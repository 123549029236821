export default {
  FORM_LABELS: {
    1: {
      formName: 'Đặt vé máy bay và khách sạn',
      order: ['fullname', 'email', 'phone', 'managerEmail', 'game', 'includesMe', 'purpose', 'from', 'to', 'colleaguesList',
        'projectCode', 'startDate1', 'startDate2', 'startDate3', 'roundTrip', 'endDate1', 'endDate2', 'endDate3',
        'room1', 'room2', 'room3', 'hanoiHotel', 'hcmHotel', 'visitHome', 'includesWeekend', 'weekendPurpose', 'weekendList', 'otherInfomation',
        'guests', 'guestReason', 'guestInformation', 'guestCCCD'],
      approveCenterColumns: ['purpose', 'from', 'to', 'startDate1', 'endDate1', 'projectCode', 'otherInfomation', 'includesWeekend', 'visitHome'],
      highlight: ['includesWeekend', 'weekendPurpose', 'weekendList'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã nhân viên',
        rank: 'Cấp bậc',
        managerEmail: 'Email quản lý trực tiếp',
        includesMe: 'Bạn đặt vé cho bản thân hay cho người khác?',
        purpose: 'Mục đích đặt vé',
        game: 'Bộ phận của bạn',
        from: 'Địa điểm xuất phát',
        to: 'Địa điểm đến',
        colleagues: 'Nhân sự đi cùng',
        colleaguesList: 'Nhân sự đi cùng',
        colleagueName: 'Họ và tên nhân sự đi cùng',
        colleagueEmail: 'Email nhân sự đi cùng',
        colleagueIdCard: 'CMND/Passport/CCCD nhân sự đi cùng',
        startDates: 'Ngày giờ xuất phát',
        startDate: 'Ngày giờ xuất phát',
        endDates: 'Ngày giờ khứ hồi',
        endDate: 'Ngày giờ khứ hồi',
        startDate1: 'Ngày giờ xuất phát: Lựa chọn 1',
        startDate2: 'Ngày giờ xuất phát: Lựa chọn 2',
        startDate3: 'Ngày giờ xuất phát: Lựa chọn 3',
        roundTrip: 'Bạn muốn đặt vé Một chiều hay Khứ hồi?',
        endDate1: 'Ngày giờ khứ hồi: Lựa chọn 1',
        endDate2: 'Ngày giờ khứ hồi: Lựa chọn 2',
        endDate3: 'Ngày giờ khứ hồi: Lựa chọn 3',

        room1: 'Số lượng phòng đơn',
        room2: 'Số lượng phòng đôi',
        room3: 'Số lượng phòng ba',

        hanoiHotel: 'Khách sạn Hà Nội',
        hcmHotel: 'Khách sạn Hồ Chí Minh',
        otherInfomation: 'Thông tin khác (Trong trường hợp bạn muốn có yêu cầu đặc biệt khác)',
        projectCode: 'Mã dự án',

        includeGuests: 'Chuyến công tác của bạn có khách mời không?',
        guests: 'Thông tin khách mời',
        guestReason: 'Lý do mời khách',
        guestInformation: 'Thông tin khác liên quan tới khách mời',
        guestName: 'Họ và tên khách',
        guest_name: 'Họ và tên khách',
        guestId: 'CMND/Passport/CCCD của khách',
        guest_id_no: 'CMND/Passport/CCCD của khách',

        includesWeekend: 'Chuyến công tác của bạn có bao gồm cuối tuần không?',
        weekendPurpose: 'Mục đích đi công tác có cả cuối tuần',
        weekendList: 'Những ngày cuối tuần trong chuyến đi',

        membersCount: 'Thông tin về số lượng nhân sự, khách mời của chuyến công tác',
        memberType: 'Người đi cùng là',
        memberName: 'Họ và tên',
        memberEmail: 'Email (đối với Nhân viên chính thức, Cộng tác viên)',
        memberIdCard: 'CMND/Passport/CCCD (đối với Cộng tác viên, khách mời, KOL)',
        memberGigiLink: `Link ticket GIGI hợp đồng (ví dụ: Gigi.garena.vn/ticket/1234)
        Hoặc Số Request (ví dụ: Request 99) trên Vendor System ghi nhận chi phí VEE sẽ thanh toán cho các chi phí khách sạn, vé máy bay`,
        memberPrLink: 'Purchase Request ghi nhận chi phí VEE thanh toán dịch vụ với Khách mời, KOLs',
        memberCsvInfo: 'Bảng thông tin nhân sự, khách mời',
        bundlePMs: 'Những nhân viên được đặt vé thuộc bộ phận nào?',
        visitHome: 'Chuyến công tác của bạn có phải về thăm nhà theo chính sách công tác dài hạn không?',
        guestCCCD: 'Link Google drive CCCD ( đầy đủ ảnh chụp 2 mặt) và Passport ( nếu thay thế) của CTV/ Khách mời/ KOLs',
      },
      description: {
        memberGigiLink: 'Add email Admin phụ trách vào Ticket Đặt vé này để nếu People Service cần thêm thông tin Đơn đặt hàng hay thắc mắc sẽ được Admin Game giải đáp'
      }
    },
    2: {
      formName: 'Báo cáo làm thêm giờ',
      order: ['fullname', 'email', 'phone', 'rank', 'managerEmail', 'staffId', 'dates'],
      approveCenterColumns: ['dates'],
      // tableFields: ['dates'],
      dates_order: ['date', 'startTime', 'endTime', 'duration', 'purpose', 'paymentType'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        rank: 'Cấp bậc',
        managerEmail: 'Email của quản lý',
        staffId: 'Mã số nhân viên',
        dates: 'Ngày giờ đăng ký làm thêm',
        date: 'Ngày',
        paymentType: 'Quyền lợi',
        purpose: 'Mục đích làm thêm giờ',
        startTime: 'Thời gian bắt đầu',
        endTime: 'Thời gian kết thúc',
        duration: 'Tổng số giờ làm thêm trong ngày',
      }
    },
    3: {
      formName: 'Rà soát hợp đồng (Đã có mẫu)',
      order: ['fullname', 'email', 'phone', 'po', 'supportedBy', 'game', 'hasFile', 'poSigned', 'pmSigned', 'legalSigned', 'financeSigned', 'currentStatus', 'noOfRejected',
        'contractValue_enterprise_1', 'contractValue_enterprise_2', 'contractValue_personal_1', 'contractValue_personal_2', 'contractValue', 'contractCurrency',
        'contractName', 'contractUrl', 'contractNumber', 'matched', 'partnerStatus', 'partnerTicket', 'location', 'partnerType', 'partner',
        'vnName', 'partnerName', 'partnerTaxCode', 'idCard', 'personalTaxCode', 'isSponsorContract', 'isMasterContract', 'information', 'informationRequirement',
        'informationProject', 'informationTime', 'claim', 'pr', 'payment', 'signingDate', 'lateReason', 'paymentTerms', 'duePaymentDate', 'includesGiftcode', 'otherInformation',
        'isDigitalContract',],
      highlight: ['contractNumber', 'matched', 'partnerStatus', 'partnerTicket', 'lateReason', 'isSponsorContract', 'isMasterContract', 'includesGiftcode'],
      mapping: {
        supportedBy: 'Admin hỗ trợ',
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        rank: 'Cấp bậc',
        po: 'Project owner\'s Email',
        contractNumber: 'Số hợp đồng',
        needContractNumber: 'Bạn có muốn lấy sẵn số Hợp đồng từ Gigi không?',
        matched: 'Tìm thấy thông tin của đối tác trên hệ thống?',
        location: 'Khu vực (Theo khu vực của người lấy số Hợp đồng)',
        companyName: 'Công ty',
        information: 'Sản phẩm bạn cần đối tác thực hiện là gì?',
        isDigitalContract: 'Hợp đồng này có thực hiện ký bằng Chữ ký Số hay không? ',
        informationRequirement: 'Yêu cầu với sản phẩm này là gì?',
        informationProject: 'Sản phẩm này phục vụ cho Dự án nào?',
        informationTime: 'Thời gian hoàn thành Sản phẩm/Dịch vụ?',
        contractUrl: 'Link download hợp đồng mẫu',
        contractName: 'Loại hợp đồng',
        partner: 'Đối tác bạn muốn ký hợp đồng',
        otherInformation: 'Các thông tin khác về hợp đồng nếu có',
        payment: 'Thanh toán',
        contractValue_enterprise_1: 'Giá trị hợp đồng chưa bao gồm VAT (1)',
        contractValue_enterprise_2: 'VAT của hợp đồng (2)',
        contractValue_personal_1: 'Tổng số tiền đã bao gồm thuế thu nhập cá nhân (1)',
        contractValue_personal_2: 'Thuế thu nhập cá nhân (2)',
        contractValue: 'Giá trị hợp đồng',
        pr: 'Link PR',
        claim: 'Purchase/ Claim number',
        contractCurrency: 'Đơn vị tiền tệ',
        includesGiftcode: 'Hợp đồng của bạn có Giftcode không?',
        partnerType: 'Loại đối tác',
        partnerAlias: 'Tên viết tắt của đối tác',
        partnerTaxCode: 'Mã số thuế của đối tác',
        personalTaxCode: 'Mã số thuế cá nhân đối tác',
        signingDate: 'Thời gian ký kết Hợp đồng',
        duePaymentDate: 'Hạn thanh toán của hợp đồng',
        lateReason: 'Lý do lên Hợp đồng muộn',
        paymentTerms: 'Hạn thanh toán của hợp đồng',

        vnName: 'Tên đầy đủ bằng tiếng Việt (Full name in Vietnamese)',
        enName: 'Tên đầy đủ bằng tiếng Anh (Full name in English)',
        nationality: 'Quốc tịch (Nationality)',
        idCard: 'Số CMT/Căn cước công dân (Personal ID No.)',
        passport: 'Số Hộ chiếu (Passport No.)',
        dob: 'Ngày sinh (Date of Birth)',
        address: 'Địa chỉ bằng tiếng Anh (Address in English)',
        establishedLocation: 'Nơi thành lập (Country of Incorporation)',
        companyRegistration: 'Mã số đăng ký Công ty (Company Registration No.)',
        establishedDate: 'Ngày thành lập Công ty (Date of Incorporation)',
        game: 'Hợp đồng này phục vụ công việc của Game/ Bộ phận gì?',
        hasTaxCode: 'Đối tác của bạn đã có mã số thuế (MST) chưa?',
        taxCode: 'Mã số thuế đối tác',
        enterpriseType: 'Form này dùng để tạo Đối tác mới trên hệ thống EBS, vui lòng cho biết Đối tác này:',
        category: 'Category',
        currency: 'Currency',
        relative: 'Is the Vendor a relative or close friend of any employee of the Company? Đối tác có mối quan hệ họ hàng hoặc bạn thân của bất kỳ nhân viên nào trong Công ty không? Nếu có, vui lòng mô tả mối quan hệ này',
        accountName: 'Account Name (Tên tài khoản)',
        accountNo: 'Account Number (Số tài khoản)',
        bankName: 'Bank Name (Code) (Tên ngân hàng)',
        bankCode: 'Branch Name (Code) (Tên chi nhánh)',
        partnerStatus: 'Trạng thái phê duyệt đối tác mới',
        partnerTicket: 'Link ticket phê duyệt đối tác mới trên Gigi',
        isMasterContract: 'Hợp đồng này có phải Hợp đồng nguyên tắc không?',
        isSponsorContract: 'Hợp đồng này có phải hợp đồng tài trợ/doanh thu không?',

        webAddress: 'Web Address',
        partnerInfoTable: 'Bảng thông tin đối tác',
        conflicOfInterest: 'Have Conflict of Interest* to Declare?',
        contactPerson: 'Contact Person, Telephone No. & Email (for Payment Advice)/Người liên hệ, số điện thoại và Email (Liên quan đến thanh toán)',
        typeOfGoods: 'Type of Services/ Goods Provided/ Loại hàng hóa dịch vụ cung cấp',
        isAffiliated: 'Whether it is an affiliated company of the Group/Có phải là Công ty liên kết của tập đoàn',
        hadRelationship: 'Whether there is a business relationship with the employees of the Group/Có mối liên hệ công việc với nhân viên của Tập đoàn',
        individualAffilicated: 'Whether any equity of the Group and its affiliated companies is held/Có vốn chủ sở hữu với Tập đoàn hoặc Công ty liên kết với Tập đoàn',
        individualRestrictionAffilicates: 'Whether there is a competition restriction with the Group and its affiliates/Có bị hạn chế cạnh tranh với Tập đoàn hoặc Công ty liên kết',

      },
      description: {
        supportedBy: `Admin hỗ trợ là người sẽ giúp bạn thực hiện hợp đồng, thanh toán. Nếu chưa rõ, vui lòng liên hệ honghanh.nguyen@garena.vn để được giải đáp.`,
        isDigitalContract: `
        Hợp đồng ký bằng <a href="https://gigi.garena.vn/article/322" target="_blank">Chữ ký Số</a> là có nghĩa là 2 bên sử dụng USB token đã được đăng ký và ký hoàn toàn trên nền tảng file PDF đã có đầy đủ chữ ký Online của Công ty mà KHÔNG CẦN phải in ra bản cứng (giấy) để Công ty ký đóng dấu sau đó đưa cho Đối tác ký đóng dấu như cách thông thường trước đó. <br/>Để thực hiện được hình thức này, vui lòng liên hệ Đối tác để nắm được thông tin. <br/>Nếu chưa rõ về vấn đề Chữ ký Số, cần liên hệ Legal phụ trách trước khi thực hiện Hợp đồng
        `,
        game: `<span class="hl">Quan trọng:</span> Khi bạn chọn Game/ Bộ phận nào thì <strong>Người phụ trách (PM) của team đó sẽ được tự động thêm vào 
        Ticket</strong> sau khi submit cũng như là người chịu trách nhiệm <strong>ký online</strong> cùng lên toàn bộ giấy tờ từ <strong>Hợp đồng, 
        Phụ lục, Đơn đặt hàng, Biên bản nghiệm thu</strong> và các giấy tờ khác nếu có`,
        contractNumber: `
        <strong>Có</strong>: Vui lòng bỏ trống<br/>
        <strong>Không</strong>: Vui lòng điền số Hợp đồng
        `,
        isSponsorContract: 'Vui lòng đọc kỹ hướng dẫn <a href="https://gigi.garena.vn/article/324" target="_blank">tại đây</a><br/>Định nghĩa: Hợp đồng tài trợ/ doanh thu là hợp đồng trong đó Đối tác tài trợ cho công ty để đổi lại các quyền lợi tương ứng.',
        information: 'VD: Sản xuất video 3D/CGI cho dự án Sổ Sứ Mệnh Valentine VP26',
        informationRequirement: `VD: <br/>Chất lượng:        Tối thiểu là Full HD/4K <br/>
        Định dạng:        MP4<br/>
        Thời lượng:        40 giây - 1 phút<br/>
        Môi trường:        1 bối cảnh duy nhất (phòng thí nghiệm)<br/>
        Nhân vật:        4 nhân vật cần độ model: 2 nhân vật chính và 2 nhân vật phụ<br/>
        Chuyển động:        Các cảnh chiến đấu chuyển động nhiều<br/>
        Hiệu ứng:        Hiệu ứng cháy nổ trong phòng`,
        informationProject: 'VD: Tết Âm lịch/ Tết Dương lịch',
        informationTime: 'VD: 12/12/2020 là ngày bàn giao file/thiết kế hoàn chỉnh',
        taxCode: `<ul>
        <li>Chi tiết cách tra cứu xem <a href="https://drive.google.com/file/d/1WGU_n9p0jtl6f7TnrNTmduhiz-MAGB_g/view" target="_blank">tại đây</a></li>
        <li class="hl">Mã số thuế cá nhân là Bắt buộc</li></ul>
        `,
        taxCodeWarning: `Đối tác chưa có Mã số thuế.<br/>Vui lòng thực hiện theo hướng dẫn sau và submit lại khi đã hoàn thành<br/><br/>
        <ul>
          <li>Bước 1: Download file bao gồm <a href="https://docs.google.com/document/d/1X34soNqAXD5Tmn9XGQMsMybNjQzceh9s/edit" target="_blank"> Giấy Ủy quyền đăng ký thuế thu nhập cá nhân và Tờ khai đăng ký thuế </a> yêu cầu Cá nhân điền, ký và <span class="hl">nộp kèm Photo CMTND/CCCD</span> cho team Nhân sự (Contact point: nhansu@garena.vn)</li><br/>
          <li>Bước 2: Sau 01 ngày làm việc kể từ ngày nộp hồ sơ tới team Nhân sự -> vui lòng check kết quả MST đối tác <a href="#" target="_blannk">tại đây</a> </li><br/>
          <li>Bước 3: Sử dụng MST team Nhân sự đã đăng ký thành công để submit lại ticket</li>
        </ul>`,
        enName: `Truy cập trang <a href="https://dangkykinhdoanh.gov.vn/" target="_blank">dangkykinhdoanh.gov.vn</a>, sử dụng mã số đăng ký Công ty để tìm tên tiếng Anh đúng theo đăng ký (nếu có).`,
        enNamePersonal: `Đảo trật tự tên theo đúng yêu cầu. Ví dụ:<br/> Vũ Ngọc Mai: Mai Ngoc Vu. <br/>Trần Thị Minh Trang: Trang Thi Minh Tran`,
        establishedDate: `Lưu ý: Truy cập trang: <a href="https://dangkykinhdoanh.gov.vn/" target="_blank">dangkykinhdoanh.gov.vn</a>, sử dụng mã số đăng ký Công ty để tìm ngày thành lập Công ty chính xác`,
        isMasterContract: 'Hợp đồng nguyên tắc là Hợp đồng chưa phát sinh chi phí tại thời điểm ký. Mỗi lần có nhu cầu sử dụng dịch vụ, bạn soạn <a href="https://gigi.garena.vn/form/28" target="_blank">Đơn đặt hàng</a> tương ứng với số Hợp đồng này.',
        otherInformation: `Vui lòng add admin tương ứng vào Watchers sau khi submit ticket<br/>`
      }
    },
    4: {
      formName: 'Đăng ký tổ chức sự kiện',
      order: ['fullname', 'email', 'phone', 'game', 'po', 'eventName', 'location', 'startDate', 'endDate', 'hasTicket'],
      events_order: ['game', 'eventName', 'location', 'startDate', 'endDate'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        po: 'Project owner',
        rank: 'Cấp bậc',
        events: 'Danh sách sự kiện đăng ký',
        eventName: 'Tên sự kiện',
        location: 'Địa điểm tổ chức sự kiện',
        startDate: 'Thời gian bắt đầu sự kiện',
        endDate: 'Thời gian kết thúc sự kiện',
        game: 'Game',
        hasTicket: 'Sự kiện của bạn có tổ chức bán vé không?',
      }
    },
    5: {
      formName: 'Rà soát hợp đồng (Chưa có mẫu)',
      order: ['fullname', 'email', 'phone', 'po', 'supportedBy', 'game', 'hasFile', 'poSigned', 'pmSigned', 'legalSigned', 'financeSigned', 'currentStatus', 'noOfRejected',
        'contractValue_enterprise_1', 'contractValue_enterprise_2', 'contractValue_personal_1', 'contractValue_personal_2', 'contractValue', 'contractCurrency',
        'contractName', 'contractUrl', 'contractNumber', 'matched', 'partnerStatus', 'partnerTicket', 'location', 'partnerType', 'partner',
        'vnName', 'partnerName', 'partnerTaxCode', 'idCard', 'personalTaxCode', 'isSponsorContract', 'isMasterContract', 'information', 'informationRequirement',
        'informationProject', 'informationTime', 'claim', 'pr', 'payment', 'signingDate', 'paymentTerms', 'duePaymentDate', 'includesGiftcode', 'otherInformation',
        'isDigitalContract',],
      highlight: ['contractNumber', 'matched', 'partnerStatus', 'partnerTicket', 'isSponsorContract', 'isMasterContract', 'includesGiftcode'],
      mapping: {
        supportedBy: 'Admin hỗ trợ',
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        rank: 'Cấp bậc',
        po: 'Project owner',
        contractNumber: 'Số hợp đồng',
        matched: 'Tìm thấy thông tin của đối tác trên hệ thống?',
        location: 'Khu vực (Theo khu vực của người lấy số Hợp đồng)',
        companyName: 'Công ty',
        information: 'Sản phẩm bạn cần đối tác thực hiện là gì?',
        informationRequirement: 'Yêu cầu với sản phẩm này là gì?',
        informationProject: 'Sản phẩm này phục vụ cho Dự án nào?',
        informationTime: 'Thời gian hoàn thành Sản phẩm/Dịch vụ?',
        contractUrl: 'Link download hợp đồng mẫu',
        contractName: 'Loại hợp đồng',
        partner: 'Đối tác bạn muốn ký hợp đồng',
        otherInformation: 'Các thông tin khác về hợp đồng nếu có',
        payment: 'Thanh toán',
        contractValue: 'Giá trị hợp đồng',
        pr: 'Link PR',
        claim: 'Purchase/ Claim number',
        includesGiftcode: 'Hợp đồng của bạn có Giftcode không?',
        partnerType: 'Loại đối tác',
        partnerAlias: 'Tên viết tắt của đối tác',
        partnerTaxCode: 'Mã số thuế của đối tác',
        personalTaxCode: 'Mã số thuế cá nhân đối tác',
        signingDate: 'Thời gian ký kết Hợp đồng',
        duePaymentDate: 'Hạn thanh toán của hợp đồng',
        paymentTerms: 'Hạn thanh toán của hợp đồng',

        vnName: 'Tên đầy đủ bằng tiếng Việt (Full name in Vietnamese)',
        enName: 'Tên đầy đủ bằng tiếng Anh (Full name in English)',
        nationality: 'Quốc tịch (Nationality)',
        idCard: 'Số CMT/Căn cước công dân (Personal ID No.)',
        passport: 'Số Hộ chiếu (Passport No.)',
        dob: 'Ngày sinh (Date of Birth)',
        address: 'Địa chỉ bằng tiếng Anh (Address in English)',
        establishedLocation: 'Nơi thành lập (Country of Incorporation)',
        companyRegistration: 'Mã số đăng ký Công ty (Company Registration No.)',
        establishedDate: 'Ngày thành lập Công ty (Date of Incorporation)',
        game: 'Bộ phận của bạn',
        taxCode: 'Mã số thuế đối tác',
        enterpriseType: 'Form này dùng để tạo Đối tác mới trên hệ thống EBS, vui lòng cho biết Đối tác này:',
        category: 'Category',
        currency: 'Currency',
        relative: 'Is the Vendor a relative or close friend of any employee of the Company? Đối tác có mối quan hệ họ hàng hoặc bạn thân của bất kỳ nhân viên nào trong Công ty không? Nếu có, vui lòng mô tả mối quan hệ này',
        accountName: 'Account Name (Tên tài khoản)',
        accountNo: 'Account Number (Số tài khoản)',
        bankName: 'Bank Name (Code) (Tên ngân hàng)',
        bankCode: 'Branch Name (Code) (Tên chi nhánh)',
        partnerStatus: 'Trạng thái phê duyệt đối tác mới',
        partnerTicket: 'Link ticket phê duyệt đối tác mới trên Gigi',
        isMasterContract: 'Hợp đồng này có phải Hợp đồng nguyên tắc không?',
        isSponsorContract: 'Hợp đồng này có phải hợp đồng tài trợ/doanh thu không?',

        webAddress: 'Web Address',
        partnerInfoTable: 'Bảng thông tin đối tác',
        conflicOfInterest: 'Have Conflict of Interest* to Declare?',
        contactPerson: 'Contact Person, Telephone No. & Email (for Payment Advice)/Người liên hệ, số điện thoại và Email (Liên quan đến thanh toán)',
        typeOfGoods: 'Type of Services/ Goods Provided/ Loại hàng hóa dịch vụ cung cấp',
        isAffiliated: 'Whether it is an affiliated company of the Group/Có phải là Công ty liên kết của tập đoàn',
        hadRelationship: 'Whether there is a business relationship with the employees of the Group/Có mối liên hệ công việc với nhân viên của Tập đoàn',
        individualAffilicated: 'Whether any equity of the Group and its affiliated companies is held/Có vốn chủ sở hữu với Tập đoàn hoặc Công ty liên kết với Tập đoàn',
        individualRestrictionAffilicates: 'Whether there is a competition restriction with the Group and its affiliates/Có bị hạn chế cạnh tranh với Tập đoàn hoặc Công ty liên kết',

      },
    },
    7: {
      formName: 'Lấy số Hợp đồng',
      order: ['formType', 'matched', 'contractNumber', 'companyName', 'location', 'partnerAlias', 'partnerName', 'signingDate', 'pr', 'claim', 'partnerType', 'companyCode', 'personTaxCode', 'partnerCode', 'partnerAddress', 'contractContent', 'fullname', 'email', 'signer', 'reviewer'],
      highlight: ['contractNumber'],
      mapping: {
        formType: 'Hợp đồng theo công ty hay theo đối tác cung cấp?',
        contractNumber: 'Số Hợp đồng',
        companyName: 'Công ty',
        location: 'Khu vực (Theo khu vực của người lấy số Hợp đồng)',
        partnerAlias: 'Tên viết tắt của đối tác',
        partnerName: 'Tên đầy đủ của đối tác',
        signingDate: 'Thời gian ký kết Hợp đồng',
        partnerType: 'Đối tác của bạn là Doanh nghiệp hay Cá nhân',
        companyCode: 'Mã số đăng ký Công ty đối tác',
        partnerCode: 'Số CMT/CCCD/Hộ Chiếu',
        matched: 'Tìm thấy thông tin của đối tác trên hệ thống?',
        partnerAddress: 'Địa chỉ của đối tác',
        contractContent: 'Nội dung Hợp đồng',
        fullname: 'Tên người phụ trách Hợp đồng',
        email: 'Email người phụ trách Hợp đồng',
        signer: 'Tên người ký Hợp đồng',
        reviewer: 'Tên người review Hợp đồng',
        personTaxCode: 'Mã số thuế cá nhân',
        pr: 'Link PR',
        claim: 'Purchase/ Claim number',
      },
      description: {
        personTaxCode: `<ul>
        <li>Vui lòng hỏi cá nhân ký hợp đồng, sau đó vào 
  <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> để tra cứu xem Mã số thuế cá nhân đã chính xác và khớp với Số chứng minh thư/ Thẻ căn cước của cá nhân chưa</li>
  <li>Nếu cá nhân ký hợp đồng không biết Mã số thuế cá nhân của họ, bạn vui lòng vào trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> điền Số chứng minh thư/Thẻ căn cước của cá nhân để lấy được Mã số thuế cá nhân.</li>
  <li>Nếu tìm trong trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> không có kết quả, vui lòng điền Chưa có</li>
  <li>Chi tiết cách tra cứu xem <a href="https://drive.google.com/file/d/1WGU_n9p0jtl6f7TnrNTmduhiz-MAGB_g/view" target="_blank">tại đây</a></li>
  <li class="hl">Mã số thuế cá nhân là Bắt buộc</li>
  <li>Nếu tìm trong trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> và làm theo Hướng dẫn không có kết quả, vui lòng điền Chưa có đồng thời Download file bao gồm <a href="https://docs.google.com/document/d/1X34soNqAXD5Tmn9XGQMsMybNjQzceh9s/edit" target="_blank"> Giấy Ủy quyền đăng ký thuế thu nhập cá nhân và Tờ khai đăng ký thuế </a> yêu cầu Cá nhân điền, ký và nộp cho team Nhân sự (Contact point: ngoctram.nguyen@garena.vn)</li>
  </ul>`,
      }
    },
    8: {
      formName: 'Lấy số và ký Online Công văn',
      order: ['isDigitalContract', 'company', 'location', 'title', 'title_other', 'content', 'dispatchDate', 'fullname', 'email', 'signer', 'to', 'archiveNumber'],
      highlight: ['archiveNumber'],
      mapping: {
        company: 'Công ty',
        location: 'Khu vực (Theo khu vực của người lấy số)',
        title: 'Nội dung',
        title_other: 'Chi tiết nội dung khác',
        content: 'Nội dung công văn (Về vấn đề gì)',
        dispatchDate: 'Ngày phát hành',
        fullname: 'Tên người soạn thảo công văn',
        email: 'Email người soạn thảo công văn',
        signer: 'Người ký',
        game: 'Bộ phận của bạn',
        archiveNumber: 'Số công văn',
        to: 'Nơi gửi đến',
        isDigitalContract: 'Công văn này có thực hiện ký bằng Chữ ký Số hay không? ',
      },
      description: {
        isDigitalContract: `
        Văn bản ký bằng <a href="https://gigi.garena.vn/article/322" target="_blank">Chữ ký Số</a> là có nghĩa là 2 bên sử dụng USB token đã được đăng ký và ký hoàn toàn trên nền tảng file PDF đã có đầy đủ chữ ký Online của Công ty mà KHÔNG CẦN phải in ra bản cứng (giấy) để Công ty ký đóng dấu sau đó đưa cho Đối tác ký đóng dấu như cách thông thường trước đó. <br/>Để thực hiện được hình thức này, vui lòng liên hệ Đối tác để nắm được thông tin. <br/>Nếu chưa rõ về vấn đề Chữ ký Số, cần liên hệ Legal phụ trách trước khi thực hiện
        `,
      }
    },
    9: {
      formName: 'Phê duyệt chi phí loại',
      order: ['fullname', 'email', 'phone', 'game', 'definition', 'amount', 'purchaseReport', 'purchaseLink', 'whyDeductable'],
      approveCenterColumns: ['game', 'definition', 'amount', 'whyDeductable'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        rank: 'Cấp bậc',
        game: 'Game',
        definition: 'Definition',
        amount: 'Amount of Purchase report/Expense report (VND)',
        purchaseReport: 'Purchase report/Expense report',
        purchaseLink: 'Link of Purchase report/Expense report',
        reason: 'Why this is Non - Deductable expense?',
        whyDeductable: 'Why this is Non - Deductable expense?',
      }
    },
    10: {
      formName: 'Thay đổi Chứng minh thư/Căn cước công dân',
      order: ['fullname', 'email', 'phone', 'staffId', 'identity', 'issueDate', 'expirationDate', 'issueLocation', 'frontImage', 'backImage'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        rank: 'Cấp bậc',
        identity: 'Số CMT/CCCD mới',
        issueDate: 'Ngày cấp CMT/CCCD',
        expirationDate: 'Ngày hết hạn CMT/CCCD',
        issueLocation: 'Nơi cấp CMT/CCCD',
        frontImage: 'Mặt trước CMT/CCCD',
        backImage: 'Mặt sau CMT/CCCD',
      }
    },
    11: {
      formName: 'Thay đổi Số tài khoản nhận lương',
      order: ['fullname', 'email', 'phone', 'staffId', 'bankAccount', 'bankName', 'branch',],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        rank: 'Cấp bậc',
        bankAccount: 'Số tài khoản ngân hàng mới',
        bankName: 'Ngân hàng',
        branch: 'Chi nhánh ngân hàng',
      }
    },
    12: {
      formName: 'Thay đổi thông tin cá nhân khác',
      order: ['fullname', 'email', 'phone', 'staffId', 'newEmail', 'newPhone', 'newAddress', 'marriageStatus',
        'marriageImage', 'childrenCnt', 'taxID', 'socialSecurityID'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email hiện tại',
        phone: 'Số điện thoại hiện tại',
        staffId: 'Mã số nhân viên',
        newEmail: 'Personal email',
        newPhone: 'Cell phone',
        marriageStatus: 'Marital Status',
        marriageImage: 'Giấy đăng ký kết hôn',
        newAddress: 'Residential Address',
        childrenCnt: 'Number of Children',
        taxID: 'Tax ID',
        socialSecurityID: 'Social Security ID',
      }
    },
    13: {
      formName: 'Đăng ký bảo hiểm sức khỏe (Chế độ CÔNG TY THANH TOÁN)',
      order: ['fullname', 'email', 'phone', 'staffId', 'relativeCnt', 'relatives'],
      relatives_order: ['relative_name', 'relation', 'country', 'gender', 'birthday', 'id_card', 'currentHealth', 'passHealth', 'feeLevel'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        relativeCnt: 'Số lượng người thân đăng ký mua',
        relatives: 'Thông tin về người thân',
        gender: 'Giới tính',
        passHealth: 'Người thân của bạn có tiền sử bệnh không?',
        id_card: 'Số CMT/CCCD',
        remark: 'Lưu ý',
        relative_name: 'Họ tên người thân',
        birthday: 'Ngày tháng năm sinh',
        relation: 'Mối quan hệ',
        country: 'Quốc tịch',
        currentHealth: 'Người thân đang theo dõi hoặc điều trị thương tật?',
        continuePurchases: 'Xác nhận đăng ký bảo hiểm cho người thân này năm 2022?',
      }
    },
    14: {
      formName: 'Đăng ký bảo hiểm sức khỏe (Chế độ TỰ NGUYỆN)',
      order: ['fullname', 'email', 'phone', 'staffId', 'relativeCnt', 'relatives'],
      relatives_order: ['relative_name', 'relation', 'country', 'gender', 'birthday', 'id_card', 'currentHealth', 'passHealth', 'feeLevel'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        relativeCnt: 'Số lượng người thân đăng ký mua',
        relatives: 'Thông tin về người thân',
        gender: 'Giới tính',
        passHealth: 'Người thân của bạn có tiền sử bệnh không?',
        id_card: 'Số CMT/CCCD',
        remark: 'Lưu ý',
        relative_name: 'Họ tên người thân',
        birthday: 'Ngày tháng năm sinh',
        relation: 'Mối quan hệ',
        country: 'Quốc tịch',
        feeLevel: 'Mức phí cho người thân',
        currentHealth: 'Người thân đang theo dõi hoặc điều trị thương tật?',
        continuePurchases: 'Xác nhận đăng ký bảo hiểm cho người thân này năm 2022?',
      }
    },
    15: {
      formName: 'Đăng ký thông tin Cộng tác viên (CTV) mới',
      order: ['contractNo', 'fullname', 'onsite', 'startDate', 'endDate', 'internName', 'internEmail',
        'benefit', 'vehicalInfo', 'internPhone', 'internBirthdayDate', 'internAddress', 'bankAccount',
        'bank', 'branch', 'internCmnd', 'internCmndIssuedDate', 'internCmndIssuedLocation', 'internCccd',
        'internCccdIssuedDate', 'internCccdIssuedLocation', 'hasTaxCode', 'taxCode', 'position', 'location',
        'team', 'internType', 'education', 'note', 'internManager',
        'confirmUnder18', 'reasonUnder18', 'hireOtherAbove18'],
      highlight: ['contractNo', 'team', 'internType', 'salaryType', 'confirmUnder18'],
      mapping: {
        fullname: 'Họ và tên người đăng ký',
        internCsvInfo: 'Thông tin CTV dưới dạng bảng',
        contractNo: 'Số hợp đồng',
        email: 'Email người đăng ký',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        internName: 'Họ và tên của CTV',
        internEmail: 'Email cá nhân của CTV',
        internCompanyEmail: 'Email công ty của CTV',
        benefit: 'Bạn muốn cấp các trang thiết bị làm việc nào',
        vehicalInfo: 'Thông tin phương tiện đi lại',
        bankAccount: 'Số tài khoản ngân hàng',
        bank: 'Tên Ngân hàng',
        branch: 'Chi nhánh',
        position: 'Vị trí công việc',
        location: 'Địa điểm làm việc',
        internAddress: 'Địa chỉ của CTV',
        team: 'Bộ phận',
        internBirthdayDate: 'Ngày tháng năm sinh của CTV',
        internCmnd: 'CMND của CTV',
        internCmndIssuedDate: 'Ngày cấp CMND của CTV',
        internCmndIssuedLocation: 'Nơi cấp CMND của CTV',
        internCccd: 'CCCD của CTV',
        internCccdIssuedDate: 'Ngày cấp CCCD của CTV',
        internCccdIssuedLocation: 'Nơi cấp CCCD của CTV',
        internPhone: 'Điện thoại của CTV',
        startDate: 'Thời gian bắt đầu cộng tác (tính theo thời gian trên HĐ CTV)',
        endDate: 'Thời gian dự kiến kết thúc cộng tác',
        salary: 'Tổng thu nhập trước thuế trên Hợp đồng (Bao gồm tiền lương + tiền cơm)',
        allowance: 'Trợ cấp kèm theo',
        internType: 'Loại Hợp đồng',
        salaryType: 'Phương thức trả lương',
        education: 'Trình độ văn hoá',
        internManager: 'Email quản lý trực tiếp của CTV (báo cáo công việc trực tiếp)',
        salaryStaff: 'Email người tính lương cho CTV',
        proposerStaff: 'Email người phụ trách raise PR/PO trả lương',
        taxCode: 'Mã số thuế cá nhân',
        lunch: 'Hỗ trợ tiền cơm trưa',
        hasTaxCode: 'CTV đã có mã số thuế (MST) chưa?',
        note: 'Thông tin đặc biệt của CTV',
        internAlias: 'Tên viết tắt của CTV',
        onsite: 'CTV có làm việc tại văn phòng không?',
        confirmUnder18: 'Bạn chắc chắn muốn tiếp tục đăng ký cho CTV dưới 18 tuổi này?',
        reasonUnder18: 'Lý do bạn muốn ký HĐ với CTV 18 tuổi này?',
        hireOtherAbove18: 'Liệu có thể thuê CTV khác trên 18 tuổi thực hiện các công việc này không?',
        appliedTime: 'Thay đổi này có hiệu lực từ thời điểm nào?',
      },
      description: {
        taxCode: `<ul>
        <li>Chi tiết cách tra cứu xem <a href="https://drive.google.com/file/d/1WGU_n9p0jtl6f7TnrNTmduhiz-MAGB_g/view" target="_blank">tại đây</a></li>
        <li class="hl">Mã số thuế cá nhân là Bắt buộc</li></ul>
        `,
        taxCodeWarning: ``,
      }
    },
    16: {
      formName: 'Đăng ký thay đổi thông tin Cộng tác viên',
      order: ['fullname', 'email', 'internName', 'position', 'location', 'old_team', 'team', 'oldSalary',
        'oldAllowance', 'internType', 'salary', 'monthDisplay', 'direct_manager',
        'email', 'phone_number', 'address', 'bank_account', 'bank_name', 'bank_branch', 'id_card', 'id_card_issued_date',
        'id_card_issued_location', 'tax_code', 'monthSalaryChangeDisplay', 'monthNotSalaryChangeDisplay',
        'changeReason', 'changeSalaryReason1', 'changeSalaryReason2', 'changeSalaryReason3'],
      highlight: ['team', 'salary', 'month'],
      ignoreKeys: ['milestones'],
      mapping: {
        fullname: 'Họ và tên người đăng ký',
        old_email: 'Email cá nhân CTV',
        old_team: 'Bộ phận hiện tại của CTV',
        milestones: 'Tiến trình tăng lương',
        email: 'Email cá nhân CTV',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        internName: 'Họ và tên của CTV',
        internEmail: 'Email của CTV',
        position: 'Vị trí công việc',
        location: 'Địa điểm làm việc',
        team: 'Bộ phận',
        internBirthdayDate: 'Ngày tháng năm sinh của CTV',
        internPhone: 'Điện thoại của CTV',
        startDate: 'Thời gian bắt đầu cộng tác (tính theo thời gian trên HĐ CTV)',
        oldSalary: 'Lương hiện tại (VNĐ)',
        oldAllowance: 'Trợ cấp kèm theo (VNĐ)',
        internType: 'Loại Hợp đồng',
        internManager: 'Email quản lý trực tiếp của CTV (báo cáo công việc trực tiếp)',
        salaryStaff: 'Email người tính lương cho CTV',
        proposerStaff: 'Email người phụ trách raise PR/PO trả lương',
        salary: 'Mức lương mới (tổng thu nhập trước thuế mỗi tháng Gross, đã bao gồm tiền lương và các khoản phụ cấp)',
        allowance: 'Mức trợ cấp mới',
        month: 'Thời gian có hiệu lực',
        monthDisplay: 'Thời gian có hiệu lực',
        monthSalaryChangeDisplay: 'Thời gian có hiệu lực (mức lương mới)',
        monthInfoChangeDisplay: 'Thời gian có hiệu lực (với các thay đổi khác lương)',
        changeReason: 'Lý do điều chỉnh',
        direct_manager: 'Email quản lý trực tiếp của CTV',
        address: 'Địa chỉ của CTV',
        bank_account: 'Số tài khoản ngân hàng',
        bank_name: 'Tên Ngân hàng',
        bank_branch: 'Chi nhánh',
        id_card: 'CCCD/CMND của CTV',
        id_card_issued_date: 'Ngày cấp CCCD/CMND của CTV',
        id_card_issued_location: 'Nơi cấp CCCD/CMND của CTV',
        tax_code: 'Mã số thuế cá nhân',
        phone_number: 'Điện thoại của CTV',
        changeSalaryReason1: 'Thay đổi về mô tả công việc hay workload nào dẫn đến sự tăng lương này',
        changeSalaryReason2: 'Thay đổi về chất lượng, năng lực con người nào dẫn đến sự tăng lương này',
        changeSalaryReason3: 'Mức lương mới này đã tham khảo và nằm trong dải lương hiện có cho CTV chưa?',
      },
      description: {
        changeReason: `
        Nếu bạn đang yêu cầu thay đổi vị trí công việc trong cùng 1 bộ phận:<br/>
        - Lý do thay đổi?<br/>
        - Cập nhật nội dung và mô tả công việc của vị trí mới?<br/>
        <br/>
        Nếu bạn đang yêu cầu thay đổi sang bộ phận khác:<br/>
        - Tóm tắt nhu cầu của bộ phận mới được chuyển sang, dựa trên workload, priority, v.v..? <br/>
        - Nội dung công việc của vị trí CTV này tại bộ phận mới?<br/>
        <br/>
        `,
        changeSalaryReason: `
        Nếu bạn đang yêu cầu thay đổi Tổng thu nhập:<br/>
        - Thay đổi về mô tả công việc hay workload nào dẫn đến sự tăng lương này?<br/>
        - Thay đổi về chất lượng, năng lực con người nào dẫn đến sự tăng lương này?<br/>
        - Mức lương mới này đã tham khảo và nằm trong dải lương hiện có cho CTV chưa? (Yes/No)?<br/>
        `
      }
    },
    17: {
      formName: 'Xoá thông tin CTV khỏi hệ thống',
      order: ['fullname', 'email', 'internName', 'internEmail', 'internCompanyEmail', 'internPhone', 'internBirthdayDate',
        'position', 'location', 'team', 'startDate', 'internType',
        'internManager', 'proposerStaff', 'lastWorkingDate'],
      mapping: {
        fullname: 'Họ và tên người đăng ký',
        email: 'Email người đăng ký',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        internName: 'Họ và tên của CTV',
        internEmail: 'Email của CTV',
        internCompanyEmail: 'Email công ty của CTV',
        position: 'Vị trí công việc',
        location: 'Địa điểm làm việc',
        team: 'Bộ phận',
        internBirthdayDate: 'Ngày tháng năm sinh của CTV',
        internPhone: 'Điện thoại của CTV',
        startDate: 'Thời gian bắt đầu cộng tác (tính theo thời gian trên HĐ CTV)',
        salary: 'Lương hiện tại trên HĐ (VNĐ)',
        allowance: 'Trợ cấp kèm theo (VNĐ)',
        internType: 'Loại CTV',
        internManager: 'Email quản lý trực tiếp của CTV (báo cáo công việc trực tiếp)',
        salaryStaff: 'Email người tính lương cho CTV',
        proposerStaff: 'Email người phụ trách raise PR/PO trả lương',
        lastWorkingDate: 'Last working day của CTV'
      }
    },
    18: {
      formName: 'Báo cáo thu nhập hàng tháng cho CTV',
      order: ['fullname', 'email', 'internName', 'position', 'location', 'team', 'month', 'oldSalary', 'oldAllowance', 'internType', 'salary', 'allowance'],
      mapping: {
        fullname: 'Họ và tên người đăng ký',
        email: 'Email người đăng ký',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        internName: 'Họ và tên của CTV',
        internEmail: 'Email của CTV',
        position: 'Vị trí công việc',
        location: 'Địa điểm làm việc',
        team: 'Bộ phận',
        internBirthdayDate: 'Ngày tháng năm sinh của CTV',
        internPhone: 'Điện thoại của CTV',
        startDate: 'Thời gian bắt đầu cộng tác (tính theo thời gian trên HĐ CTV)',
        oldSalary: 'Lương hiện tại (VNĐ)',
        oldAllowance: 'Trợ cấp kèm theo (VNĐ)',
        internType: 'Loại CTV',
        internManager: 'Email quản lý trực tiếp của CTV (báo cáo công việc trực tiếp)',
        salaryStaff: 'Email người tính lương cho CTV',
        proposerStaff: 'Email người phụ trách raise PR/PO trả lương',
        salary: 'Thu nhập của CTV',
        allowance: 'Mức trợ cấp mới',
        pr: 'Link PR',
        month: 'Báo cáo cho tháng',
      }
    },
    19: {
      formName: 'Báo cáo phụ cấp lưu trú đi Công tác dài hạn',
      order: ['fullname', 'email', 'phone', 'staffId', 'month', 'to', 'businessType', 'dates'],
      dates_order: ['startTime', 'endTime', 'reason'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        team: 'Bộ phận làm việc',
        to: 'Nơi công tác',
        dates: 'Thời gian đi khỏi địa điểm công tác',
        startTime: 'Từ',
        endTime: 'Đến',
        reason: 'Lý do',
        month: 'Báo cáo cho tháng',
        businessType: 'Lựa chọn hình thức đi công tác trong tháng'
      }
    },
    20: {
      formName: 'Báo cáo thu nhập hàng tháng cho CTV Thường xuyên',
      order: ['fullname', 'email', 'team', 'month', 'po', 'note', 'collaboratorsText',],
      collaborators_order: ['collaboratorName', 'salary', 'pr'],
      tableFields: ['collaboratorsText'],
      tableFieldsHidden: ['collaboratorId',],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        team: 'Báo cáo cho bộ phận',
        month: 'Báo cáo cho tháng',
        collaboratorsText: 'Tổng hợp thu nhập tháng CTV',
        collaboratorName: 'Họ tên CTV',
        salary: 'Thu nhập của CTV',
        pr: 'Link PR',
        po: 'Số PO',
        note: 'Ghi chú đặc biệt khác',
      },
      description: {
        collaboratorsText: 'Liệt kê danh sách<br/><span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê</span>'
      }
    },
    21: {
      formName: 'Xếp số Thanh toán',
      order: ['paymentDate', 'requester', 'paymentType', 'location', 'game',
        'paymentType', 'pr', 'contractValue', 'contractValueNotIncludePIT',
        'partnerName', 'expectedPaymentDate', 'dueDate', 'queueNumber',
        'requestIdInVendorSystem', 'orderIdInVendorSystem'],
      highlight: ['queueNumber'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        requester: 'Requester\'s email',
        location: 'Địa điểm submit hồ sơ',
        pr: 'Purchase Order (PO) number hoặc Claim number ( Expense report )',
        queueNumber: 'Số thứ tự thanh toán',
        game: 'Bộ phận của bạn',
        dueDate: 'Due date',
        expectedPaymentDate: 'Expected payment date',
        paymentDate: 'Ngày được thanh toán thành công',
        paymentType: 'Loại thanh toán',
        contractValue: 'Số tiền cần thanh toán',
        contractValueNotIncludePIT: 'Số tiền thực tế thanh toán cho cá nhân sau khi trừ PIT',
        partnerName: 'Thanh toán cho đối tác nào',
        requestIdInVendorSystem: 'Thanh toán này liên quan đến Vendor request',
        orderIdInVendorSystem: 'Thanh toán này liên quan đến Vendor order',
      }
    },
    22: {
      formName: 'Rà soát hợp đồng (bundle)',
      order: ['fullname', 'email', 'phone', 'po', 'supportedBy', 'game', 'hasFile', 'legalSigned', 'legalSignedOn',
        'financeSigned', 'financeSignedOn', 'currentStatus', 'noOfRejected', 'totalContractValue', 'partners', 'contractName',
        'contractUrl', 'contractNumber', 'partnerStatus', 'partnerTicket', 'location', 'partnerType', 'partner', 'vnName',
        'partnerName', 'partnerTaxCode', 'idCard', 'personalTaxCode', 'information', 'informationRequirement', 'informationProject',
        'informationTime', 'claim', 'pr', 'contractValue', 'payment', 'signingDate', 'paymentTerms', 'duePaymentDate',
        'includesGiftcode', 'otherInformation', 'isDigitalContract',],
      partners_order: ['vnName', 'idCard', 'companyRegistration', 'taxCode', 'matched', 'contractValue', 'contractNumber', 'address'],
      highlight: ['contractNumber', 'matched', 'partnerStatus', 'partnerTicket', 'includesGiftcode'],
      mapping: {
        supportedBy: 'Admin hỗ trợ',
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        rank: 'Cấp bậc',
        po: 'Project owner',
        contractNumber: 'Số hợp đồng',
        matched: 'Tìm thấy thông tin của đối tác trên hệ thống?',
        location: 'Khu vực (Theo khu vực của người lấy số Hợp đồng)',
        companyName: 'Công ty',
        information: 'Sản phẩm bạn cần đối tác thực hiện là gì?',
        informationRequirement: 'Yêu cầu với sản phẩm này là gì?',
        informationProject: 'Sản phẩm này phục vụ cho Dự án nào?',
        informationTime: 'Thời gian hoàn thành Sản phẩm/Dịch vụ?',
        contractUrl: 'Link download hợp đồng mẫu',
        contractName: 'Loại hợp đồng',
        partners: 'Thông tin Đối tác',
        partner: 'Đối tác bạn muốn ký hợp đồng',
        otherInformation: 'Các thông tin khác về hợp đồng nếu có',
        payment: 'Thanh toán',
        contractValue: 'Giá trị hợp đồng',
        pr: 'Link PR',
        claim: 'Purchase/ Claim number',
        includesGiftcode: 'Hợp đồng của bạn có Giftcode không?',
        partnerType: 'Loại đối tác',
        partnerAlias: 'Tên viết tắt của đối tác',
        partnerTaxCode: 'Mã số thuế của đối tác',
        personalTaxCode: 'Mã số thuế cá nhân đối tác',
        signingDate: 'Thời gian ký kết Hợp đồng',
        duePaymentDate: 'Hạn thanh toán của hợp đồng',
        paymentTerms: 'Hạn thanh toán của hợp đồng',
        newPartnerTicketIds: 'Link đăng ký tạo đối tác mới',

        vnName: 'Tên đầy đủ bằng tiếng Việt (Full name in Vietnamese)',
        enName: 'Tên đầy đủ bằng tiếng Anh (Full name in English)',
        nationality: 'Quốc tịch (Nationality)',
        idCard: 'Số CMT/Căn cước công dân (Personal ID No.)',
        passport: 'Số Hộ chiếu (Passport No.)',
        dob: 'Ngày sinh (Date of Birth)',
        address: 'Địa chỉ bằng tiếng Anh (Address in English)',
        establishedLocation: 'Nơi thành lập (Country of Incorporation)',
        companyRegistration: 'Mã số đăng ký Công ty (Company Registration No.)',
        establishedDate: 'Ngày thành lập Công ty (Date of Incorporation)',
        game: 'Hợp đồng này phục vụ công việc của Game/ Bộ phận gì?',
        hasTaxCode: 'Đối tác của bạn đã có mã số thuế (MST) chưa?',
        taxCode: 'Mã số thuế đối tác',
        enterpriseType: 'Form này dùng để tạo Đối tác mới trên hệ thống EBS, vui lòng cho biết Đối tác này:',
        category: 'Category',
        currency: 'Currency',
        relative: 'Is the Vendor a relative or close friend of any employee of the Company? Đối tác có mối quan hệ họ hàng hoặc bạn thân của bất kỳ nhân viên nào trong Công ty không? Nếu có, vui lòng mô tả mối quan hệ này',
        accountName: 'Account Name (Tên tài khoản)',
        accountNo: 'Account Number (Số tài khoản)',
        bankName: 'Bank Name (Code) (Tên ngân hàng)',
        bankCode: 'Branch Name (Code) (Tên chi nhánh)',
        partnerStatus: 'Trạng thái phê duyệt đối tác mới',
        partnerTicket: 'Link ticket phê duyệt đối tác mới trên Gigi',
        isDigitalContract: 'Hợp đồng này có thực hiện ký bằng Chữ ký Số hay không? ',
      },
      description: {
        supportedBy: `Admin hỗ trợ là người sẽ giúp bạn thực hiện hợp đồng, thanh toán. Nếu chưa rõ, vui lòng liên hệ honghanh.nguyen@garena.vn để được giải đáp.`,
        isDigitalContract: `
        Hợp đồng ký bằng <a href="https://gigi.garena.vn/article/322" target="_blank">Chữ ký Số</a> là có nghĩa là 2 bên sử dụng USB token đã được đăng ký và ký hoàn toàn trên nền tảng file PDF đã có đầy đủ chữ ký Online của Công ty mà KHÔNG CẦN phải in ra bản cứng (giấy) để Công ty ký đóng dấu sau đó đưa cho Đối tác ký đóng dấu như cách thông thường trước đó. <br/>Để thực hiện được hình thức này, vui lòng liên hệ Đối tác để nắm được thông tin. <br/>Nếu chưa rõ về vấn đề Chữ ký Số, cần liên hệ Legal phụ trách trước khi thực hiện Hợp đồng
        `,
        game: `<span class="hl">Quan trọng:</span> Khi bạn chọn Game/ Bộ phận nào thì <strong>Người phụ trách (PM) của team đó sẽ được tự động thêm vào 
        Ticket</strong> sau khi submit cũng như là người chịu trách nhiệm <strong>ký online</strong> cùng lên toàn bộ giấy tờ từ <strong>Hợp đồng, 
        Phụ lục, Đơn đặt hàng, Biên bản nghiệm thu</strong> và các giấy tờ khác nếu có`,
        information: 'VD: Sản xuất video 3D/CGI cho dự án Sổ Sứ Mệnh Valentine VP26',
        informationRequirement: `VD: <br/>Chất lượng:        Tối thiểu là Full HD/4K <br/>
        Định dạng:        MP4<br/>
        Thời lượng:        40 giây - 1 phút<br/>
        Môi trường:        1 bối cảnh duy nhất (phòng thí nghiệm)<br/>
        Nhân vật:        4 nhân vật cần độ model: 2 nhân vật chính và 2 nhân vật phụ<br/>
        Chuyển động:        Các cảnh chiến đấu chuyển động nhiều<br/>
        Hiệu ứng:        Hiệu ứng cháy nổ trong phòng`,
        informationProject: 'VD: Tết Âm lịch/ Tết Dương lịch',
        informationTime: 'VD: 12/12/2020 là ngày bàn giao file/thiết kế hoàn chỉnh',
        taxCode: `<>
        <li>Chi tiết cách tra cứu xem <a href="https://drive.google.com/file/d/1WGU_n9p0jtl6f7TnrNTmduhiz-MAGB_g/view" target="_blank">tại đây</a></li>
        <li>Vui lòng hỏi cá nhân ký hợp đồng, sau đó vào 
  <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> để tra cứu xem Mã số thuế cá nhân đã chính xác và khớp với Số chứng minh thư/ Thẻ căn cước của cá nhân chưa</li>
  <li>Nếu cá nhân ký hợp đồng không biết Mã số thuế cá nhân của họ, bạn vui lòng vào trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> điền Số chứng minh thư/Thẻ căn cước của cá nhân để lấy được Mã số thuế cá nhân.</li>
  <li>Nếu tìm trong trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> không có kết quả, vui lòng điền Chưa có</li>
  <li>Chi tiết cách tra cứu xem <a href="https://drive.google.com/file/d/1WGU_n9p0jtl6f7TnrNTmduhiz-MAGB_g/view" target="_blank">tại đây</a></li>
  <li class="hl">Mã số thuế cá nhân là Bắt buộc</li>
  <li>Nếu tìm trong trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> và làm theo Hướng dẫn không có kết quả, vui lòng điền Chưa có đồng thời Download file bao gồm <a href="https://docs.google.com/document/d/1X34soNqAXD5Tmn9XGQMsMybNjQzceh9s/edit" target="_blank"> Giấy Ủy quyền đăng ký thuế thu nhập cá nhân và Tờ khai đăng ký thuế </a> yêu cầu Cá nhân điền, ký và <span class="hl">nộp kèm Photo CMTND/CCCD</span> cho team Nhân sự (Contact point: ngoctram.nguyen@garena.vn)</li>
  </ul>`,
        enName: `Truy cập trang <a href="https://dangkykinhdoanh.gov.vn/" target="_blank">dangkykinhdoanh.gov.vn</a>, sử dụng mã số đăng ký Công ty để tìm tên tiếng Anh đúng theo đăng ký (nếu có).`,
        enNamePersonal: `Đảo trật tự tên theo đúng yêu cầu. Ví dụ:<br/> Vũ Ngọc Mai: Mai Ngoc Vu. <br/>Trần Thị Minh Trang: Trang Thi Minh Tran`,
        establishedDate: `Lưu ý: Truy cập trang: <a href="https://dangkykinhdoanh.gov.vn/" target="_blank">dangkykinhdoanh.gov.vn</a>, sử dụng mã số đăng ký Công ty để tìm ngày thành lập Công ty chính xác`,
      }
    },
    25: {
      formName: 'Phê duyệt đối tác mới/ New Vendor Request Form',
      order: ['fullname', 'email', 'phone', 'staffId', 'pr', 'claim', 'type', 'individualType', 'vnName',
        'partnerInfoTable',
        'enName', 'dob', 'nationality', 'idCard', 'passport', 'address', 'establishedLocation',
        'companyRegistration', 'establishedDate', 'taxCode', 'enterpriseType', 'category',
        'currency', 'webAddress', 'paymentTerms', 'relative', 'accountName', 'accountNo', 'bankName', 'bankCode',
        'conflicOfInterest', 'contactPerson', 'typeOfGoods', 'isAffiliated', 'hadRelationship',
        'individualAffilicated', 'individualRestrictionAffilicates',
        'scanCCCD1Image', 'scanCCCD2Image', 'scanDKKD', 'scanDKKD1Image', 'scanDKKD2Image', 'scanBankStatementImage',
      ],
      tableFields: ['partnerInfoTable'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        type: 'Loại đối tác',
        matched: 'Tìm thấy thông tin của đối tác trên hệ thống?',
        vnName: 'Tên đầy đủ bằng tiếng Việt( Full name in Vietnamese)',
        enName: 'Tên đầy đủ bằng tiếng Anh (Full name in English)',
        nationality: 'Quốc tịch (Nationality)',
        idCard: 'Số CMT/Căn cước công dân (Personal ID No.)',
        passport: 'Số Hộ chiếu (Passport No.)',
        dob: 'Ngày sinh (Date of Birth)',
        individualType: 'Cá nhân này sẽ ký hợp đồng với',
        address: 'Địa chỉ bằng tiếng Anh (Address in English)',
        establishedLocation: 'Nơi thành lập (Country of Incorporation)',
        companyRegistration: 'Mã số đăng ký Công ty (Company Registration No.)',
        establishedDate: 'Ngày thành lập Công ty (Date of Incorporation)',
        game: 'Bộ phận của bạn',
        hasTaxCode: 'Đối tác đã có Mã số thuế chưa?',
        taxCode: 'Mã số thuế đối tác',
        enterpriseType: 'Form này dùng để tạo Đối tác mới trên hệ thống EBS, vui lòng cho biết Đối tác này:/This form is used for requesting new vendor account creation in EBS',
        category: 'Category',
        currency: 'Currency',
        relative: 'Is the Vendor a relative or close friend of any employee of the Company? Đối tác có mối quan hệ họ hàng hoặc bạn thân của bất kỳ nhân viên nào trong Công ty không? Nếu có, vui lòng mô tả mối quan hệ này',
        accountName: 'Account Name (Tên tài khoản)',
        accountNo: 'Account Number (Số tài khoản)',
        bankName: 'Bank Name (Code) (Tên ngân hàng)',
        bankCode: 'Branch Name (Code) (Tên chi nhánh)',
        pr: 'Link PR',
        claim: 'Purchase/ Claim number',
        paymentTerms: 'Payment Terms/ Due date: Bao lâu phải thanh toán',
        webAddress: 'Web Address',
        partnerInfoTable: 'Bảng thông tin đối tác',
        conflicOfInterest: 'Have Conflict of Interest* to Declare?',
        contactPerson: 'Contact Person, Telephone No. & Email (for Payment Advice)/Người liên hệ, số điện thoại và Email (Liên quan đến thanh toán)',
        typeOfGoods: 'Type of Services/ Goods Provided/ Loại hàng hóa dịch vụ cung cấp',
        isAffiliated: 'Whether it is an affiliated company of the Group/Có phải là Công ty liên kết của tập đoàn',
        hadRelationship: 'Whether there is a business relationship with the employees of the Group/Có mối liên hệ công việc với nhân viên của Tập đoàn',
        individualAffilicated: 'Whether any equity of the Group and its affiliated companies is held/Có vốn chủ sở hữu với Tập đoàn hoặc Công ty liên kết với Tập đoàn',
        individualRestrictionAffilicates: 'Whether there is a competition restriction with the Group and its affiliates/Có bị hạn chế cạnh tranh với Tập đoàn hoặc Công ty liên kết',
        scanCCCD1Image: 'Ảnh scan CMND/CCCD cho cá nhân mặt trước',
        scanCCCD2Image: 'Ảnh scan CMND/CCCD cho cá nhân mặt sau',
        scanDKKD1Image: 'Ảnh scan giấy ĐKKD cho Công ty trang 1',
        scanDKKD2Image: 'Ảnh scan giấy ĐKKD cho Công ty trang 2',
        scanDKKD: 'Ảnh scan giấy ĐKKD cho Công ty',
        scanBankStatementImage: 'Ảnh scan Form xác nhận tài khoản ngân hàng của Công ty',
      },
      description: {
        taxCode: `<ul>
        <li>Vui lòng hỏi cá nhân ký hợp đồng, sau đó vào 
  <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> để tra cứu xem Mã số thuế cá nhân đã chính xác và khớp với Số chứng minh thư/ Thẻ căn cước của cá nhân chưa</li>
  <li>Nếu cá nhân ký hợp đồng không biết Mã số thuế cá nhân của họ, bạn vui lòng vào trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> điền Số chứng minh thư/Thẻ căn cước của cá nhân để lấy được Mã số thuế cá nhân.</li>
  <li>Nếu tìm trong trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> không có kết quả, vui lòng điền Chưa có</li>
  <li>Chi tiết cách tra cứu xem <a href="https://drive.google.com/file/d/1WGU_n9p0jtl6f7TnrNTmduhiz-MAGB_g/view" target="_blank">tại đây</a></li>
  <li class="hl">Mã số thuế cá nhân là Bắt buộc</li>
  <li>Nếu tìm trong trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> và làm theo Hướng dẫn không có kết quả, vui lòng điền Chưa có đồng thời Download file bao gồm <a href="https://docs.google.com/document/d/1X34soNqAXD5Tmn9XGQMsMybNjQzceh9s/edit" target="_blank"> Giấy Ủy quyền đăng ký thuế thu nhập cá nhân và Tờ khai đăng ký thuế </a> yêu cầu Cá nhân điền, ký và nộp cho team Nhân sự (Contact point: ngoctram.nguyen@garena.vn)</li>
  </ul>`,
        enName: `Truy cập trang <a href="https://dangkykinhdoanh.gov.vn/" target="_blank">dangkykinhdoanh.gov.vn</a>, sử dụng mã số đăng ký Công ty để tìm tên tiếng Anh đúng theo đăng ký (nếu có).`,
        enNamePersonal: `Đảo trật tự tên theo đúng yêu cầu. Ví dụ:<br/> Vũ Ngọc Mai: Mai Ngoc Vu. <br/>Trần Thị Minh Trang: Trang Thi Minh Tran`,
        establishedDate: `Lưu ý: Truy cập trang: <a href="https://dangkykinhdoanh.gov.vn/" target="_blank">dangkykinhdoanh.gov.vn</a>, sử dụng mã số đăng ký Công ty để tìm ngày thành lập Công ty chính xác`
      }
    },
    26: {
      formName: 'Quyết định nội bộ',
      order: ['fix_0', 'fix_1', 'signingDay', 'fix_2', 'fix_3', 'game', 'program', 'location', 'format', 'startDate', 'endDate', 'programGoods', 'programCustomer', 'programStructure', 'programAmount', 'fix_4', 'fix_5'],
      tableFields: ['programGoods', 'programStructure'],
      fieldsHidden: ['signingDay',],
      noPrint: ['game'],
      inlineStyle: {
        fix_0: {
          float: 'left',
          borderRadius: '0rem',
          width: '40%',
          textAlign: 'center'
        },
        fix_1: {
          float: 'right',
          borderRadius: '0rem',
          width: '50%',
          marginTop: '-7rem',
        },
        'signingDay': {
          fontWeight: 'normal',
          fontStyle: 'italic',
          width: '100%',
          textAlign: 'right',
          borderRadius: '0rem'
        },
        'fix_5': {
          maxWidth: '28rem',
          width: '28rem',
          float: 'right',
        }
      },
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        game: 'Bộ phận của bạn',
        po: 'Project Owner',
        docType: 'Loại Giấy tờ',
        program: 'Tên chương trình khuyến mại',
        format: 'Hình thức khuyến mại',
        startDate: 'Thời gian bắt đầu khuyến mại',
        endDate: 'Thời gian kết thúc khuyến mại',
        location: 'Địa bàn (phạm vi) khuyến mại',
        information: 'Nội dung cônng việc',
        pr: 'Link PR',
        claim: 'Purchase/ Claim number',
        programGoods: 'Hàng hoá, dịch vụ khuyến mại',
        programCustomer: 'Khách hàng của chương trình khuyến mại (đối tượng được hưởng khuyến mại)',
        programStructure: 'Cơ cấu quà tặng (nội dung, giá trị, số lượng)',
        programAmount: 'Nội dung chi tiết của chương trình khuyến mại',
        signingDay: 'Ngày ký Quyết Định'
      },
      fixContent: {
        fix_0: `<span style="text-align:center;font-weight:bold;">CÔNG TY CỔ PHẦN GIẢI TRÍ VÀ THỂ THAO ĐIỆN TỬ VIỆT NAM</span>`,
        fix_1: `<div style="text-align:center;font-weight:bold;">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div><div style="text-align:center;font-weight:bold;font-size:14px">Độc lập - Tự do - Hạnh phúc</div><div style="text-align:center;font-weight:bold;font-size:14px">---------------</div>`,
        fix_2: `<div style="text-align:center;font-weight:bold;">QUYẾT ĐỊNH</div><div style="text-align:center;font-style:italic;font-size:14px">V/V: Tổ chức chương trình khuyến mại</div>
        <div style="text-align:center;font-weight:bold;">TỔNG GIÁM ĐỐC</div><ul style="text-align:left;font-style:italic;font-size:14px">
          <li>Căn cứ vào Điều lệ tổ chức và hoạt động của Công ty;</li>
          <li>Căn cứ vào chức năng, nhiệm vụ của Tổng giám đốc</li>
          <li>Căn cứ vào yêu cầu hoạt động kinh doanh;</li>
        </ul><strong>Điều 1:</strong> Tổ chức chương trình khuyến mại sau với nội dung cụ thể được quy định trong Thể lệ kèm theo Quyết định này
        <strong>Điều 2:</strong> Quyết định này có hiệu lực thi hành từ ngày ký
        <strong>Điều 3:</strong> Các bộ phận, phòng ban có liên quan có trách nhiệm thi hành quyết định này
        `,
        fix_3: `<div style="text-align: center"><strong>THỂ LỆ CHƯƠNG TRÌNH KHUYẾN MÃI</strong></div>
        <div>Căn cứ Luật thương mại, Nghị định số 81/2018/NĐ-CP ngày 22 tháng 5 năm 2018 của Chính phủ quy định chi tiết Luật thương mại về hoạt động xúc tiến thương mại, Công ty cổ phần Giải trí và Thể thao điện tử Việt Nam thông báo chương trình khuyến mại như sau:</div>`,
        fix_4: `<strong>Các quy định khác</strong>
        a.	Các phần thưởng là hiện vật sẽ không được quy đổi thành tiền mặt.
        b.	Giá trị giải thưởng hiện vật đã bao gồm thuế giá trị gia tăng và thuế thu nhập cá nhân. Công ty Cổ phần Giải Trí và Thể Thao Điện Tử Việt Nam chịu trách nhiệm khấu trừ và nộp thuế.
        c.	Trừ các khoản thuế do Công ty cổ phần Giải trí và Thể thao điện tử Việt Nam chịu trách nhiệm chi trả được quy định trong Thể lệ này, Khách hàng có trách nhiệm chi trả các khoản chi phí khác theo quy định của pháp luật để nhận giải thưởng.
        d.	Khách hàng trúng thưởng đồng ý, Công ty sẽ sử dụng tên và hình ảnh của khách hàng trúng thưởng cho mục đích quảng cáo thương mại.
        e.	Công ty Cổ phần Giải trí và Thể thao Điện tử Việt Nam hoàn toàn chịu trách nhiệm việc tổ chức chương trình khuyến mại phải đảm bảo tính công bằng, minh bạch và khách quan.
        f.	 Trong trường hợp xảy ra tranh chấp liên quan đến chương trình khuyến mãi này, Công ty Cổ phần Giải trí và Thể thao Điện tử Việt Nam có trách nhiệm trực tiếp giải quyết, nếu không thỏa thuận được tranh chấp sẽ được xử lý theo quy định của pháp luật Việt Nam hiện hành.
        g.	Nhân viên của Công ty cổ phần Giải trí và Thể thao điện tử Việt Nam, các công ty liên kết khác của Công ty cổ phần Giải trí và Thể thao điện tử Việt Nam không được tham gia chương trình này.<br/>
        <strong>Nơi nhận</strong>
        <ul><li>Như Điều 3</li><li>Lưu: VT</li></ul>`,
        fix_5: `<div style="text-align:center;font-weight:bold">CÔNG TY CỔ PHẦN GIẢI TRÍ VÀ THỂ THAO ĐIỆN TỬ VIỆT NAM</div><div style="text-align:center;font-weight:bold;font-style:italic">Tổng giám đốc</div><div style="text-align:center;font-weight:bold;font-style:italic">Đã ký</div><div style="text-align:center;font-weight:bold;">VŨ CHÍ CÔNG</div>
        `
      },
      description: {
        location: `Điền tên tỉnh/thành phố nơi thực hiện khuyến mãi.<br/>Nếu tổ chức tại nhiều tỉnh thì ghi tên từng tỉnh. <br/>Nếu tổ chức trực tuyến thì ghi Toàn quốc`,
        format: `Lựa chọn một hoặc một số hình thức khuyến mãi phù hợp với chương trình dự định đăng ký. Đây là các hình thức theo quy định của pháp luật bắt buộc phải chọn. Nếu không chọn được vui lòng liên hệ thitheu.do@garena.vn<br/>(i)Đưa hàng mẫu cung ứng dịch vụ để khách hàng dùng thử không phải trả tiền
        <br/>(ii)Tặng hàng hoá, cung ứng dịch vụ không thu tiền
        <br/>(iii)Khuyến mại bằng hình thức giảm gía
        <br/>(iv)Bán hàng, cung ứng dịch vụ có kèm theo phiếu mua hàng
        <br/>(v)Bán hàng, cung ứng dịch vụ có kèm theo phiếu dự thi cho khách hàng để chọn người trao thưởng theo thể lệ và giải thưởng đã công bố
        <br/>(vi)Tổ chức chương trình khách hàng thường xuyên
        <br/>(vii)Tổ chức cho khách hàng tham gia các chương trình văn hoá, nghệ thuật, giải trí và các sự kiện khác vì mục đích khuyến mãi
        <br/>(viii)Bốc thăm, quay số may mắn mà việc tham gia chương trình không đi kèm với việc mua hàng hoá, sử dụng dịch vụ
        `,
        programGoods: `Liệt kê các loại hàng hóa, dịch vụ (nếu có). Tổng giá trị phải dưới 100 triệu. 
        Nếu tròn 100 triệu, phải thực hiện thủ tục khác, vui lòng xem hướng dẫn tại đây <a href="https://gigi.garena.vn/article/129" target="_blank">tại đây </a>
        <br/><br/>
        <strong>
        Công ty chịu toàn bộ chi phí bao gồm thuế thu nhập cá nhân(TNCN) cho người nhận giải thưởng Hiện vật 
        (Khách hàng), các bạn lưu ý cách tính chi tiết TỪNG SỰ KIỆN để ghi vào Thông báo khuyến mãi 
        / Quyết định nội bộ/ lên PR như trong <a href="https://docs.google.com/spreadsheets/d/1m-G_2vlzuMJpVWIZ3A3xA_WPG6FBvswLARS3Ymt52V4/edit#gid=522722066" target="_blank">file sau</a> (các tab PIT Giải thưởng hiện vật) 
        <br/><span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span><br/>
        </strong>`,
        programStructure: `Mô tả cơ cấu quà tặng
        <br/>
        <strong>
        Công ty chịu toàn bộ chi phí bao gồm thuế thu nhập cá nhân(TNCN) cho người nhận giải thưởng Hiện vật 
        (Khách hàng), các bạn lưu ý cách tính chi tiết TỪNG SỰ KIỆN để ghi vào Thông báo khuyến mãi 
        / Quyết định nội bộ/ lên PR như trong <a href="https://docs.google.com/spreadsheets/d/1m-G_2vlzuMJpVWIZ3A3xA_WPG6FBvswLARS3Ymt52V4/edit#gid=522722066" target="_blank">file sau</a> (các tab PIT Giải thưởng hiện vật) 
        <br/><span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span>
        </strong>`,
        programAmount: ``
      }
    },
    27: {
      formName: 'Quyết định cử đi công tác',
      order: ['fix_1', 'fix_2', 'signingDay', 'fix_3', 'trips', 'fix_4', 'fix_5'],
      tableFields: ['trips'],
      tableFieldsHidden: ['Email', 'gigiTicketId'],
      fieldsHidden: ['signingDay', 'trips'],
      inlineStyle: {
        fix_1: {
          float: 'left',
          borderRadius: '0rem',
          width: '40%',
          textAlign: 'center'
        },
        fix_2: {
          float: 'right',
          borderRadius: '0rem',
          width: '50%',
          marginTop: '-7rem',
        },
        'signingDay': {
          fontWeight: 'normal',
          fontStyle: 'italic',
          width: '100%',
          textAlign: 'right',
          borderRadius: '0rem'
        },
        'fix_5': {
          maxWidth: '28rem',
          width: '28rem',
          float: 'right',
        }
      },
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        game: 'Bộ phận của bạn',
        po: 'Project Owner',
        companyName: 'Công ty',
        program: 'Tên chương trình',
        format: 'Hình thức khuyến mãi',
        pr: 'Link PR',
        trips: 'Danh sách nhân viên đi công tác',
        claim: 'Purchase/ Claim number',
        signingDay: 'Ngày ký Quyết Định',
        dataTicketId: 'Quyết định này liên quan đến ticket booking nào? Điền số ticket',
      },
      fixContent: {
        fix_1: `<span style="text-align:center;font-weight:bold;">CÔNG TY CỔ PHẦN GIẢI TRÍ VÀ THỂ THAO ĐIỆN TỬ VIỆT NAM</span>`,
        fix_2: `<div style="text-align:center;font-weight:bold;">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div><div style="text-align:center;font-weight:bold;font-size:14px">Độc lập - Tự do - Hạnh phúc</div><div style="text-align:center;font-weight:bold;font-size:14px">---------------</div>`,
        fix_3: `
        <ul><li>Căn cứ Luật Doanh nghiệp 2014;</li>
        <li>Căn cứ Điều lệ Công ty;</li></ul><div style="text-align:center;font-weight:bold">QUYẾT ĐỊNH</div>
        <strong>Điều 1.</strong> Cử nhân sự sau đi công tác, làm việc tại địa điểm và thời gian cụ thể như sau:        
        `,
        fix_4: `<strong>Điều 2.</strong> Chi phí cho chuyến công tác được thực hiện như sau:<ul>
        <li>Vé máy bay: Công ty thanh toán theo chính sách;</li>
        <li>Phụ cấp lưu trú: Theo chính sách của Công ty</li>
        <li>Chi phí di chuyển: Theo chi phí phát sinh thực tế và theo chính sách Công ty</li>
        <li>Chi phí khác: Theo chính sách của Công ty</li>
        </ul><strong>Điều 3.</strong> Nhân sự liên quan tại Điều 1, các bộ phận kế toán, hành chính chịu trách nhiệm thi hành quyết định này.        
        Quyết định này có hiệu lực kể từ ngày ký<br/>
        <strong>Nơi nhận</strong>
        <ul><li>Như Điều 3</li><li>Lưu: VT</li></ul>`,
        fix_5: `
          <strong>Tổng Giám Đốc Vũ Chí Công đã ký</strong>
        `
      },
      description: {
        trips: `Liệt kê danh sách các nhân viên đi công tác<br/><span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê</span>`,
      }
    },
    28: {
      formName: 'Ký Đơn đặt hàng/Phụ lục',
      order: ['fullname', 'email', 'phone', 'po', 'supportedBy', 'game', 'isSponsorContract', 'hasFile', 'poSigned', 'pmSigned', 'legalSigned',
        'financeSigned', 'currentStatus', 'noOfRejected', 'appendixType', 'partners', 'contractNumber', 'contractValue',
        'contractValue_enterprise_1', 'contractValue_enterprise_2', 'contractValue_personal_1', 'contractValue_personal_2', 'appendixNumber', 'appendixValue', 'currency', 'contractName', 'contractUrl', 'location', 'partnerType', 'partnerName', 'partner', 'vnName', 'partnerTaxCode', 'idCard', 'personalTaxCode', 'information', 'informationRequirement', 'informationProject', 'informationTime', 'claim', 'pr', 'payment', 'signingDate', 'paymentTerms', 'duePaymentDate', 'includesGiftcode', 'otherInformation'],
      partners_order: ['vnName', 'idCard', 'companyRegistration', 'taxCode', 'matched', 'contractValue', 'contractNumber', 'address'],
      highlight: ['isSponsorContract', 'appendixNumber', 'matched', 'partnerStatus', 'partnerTicket', 'includesGiftcode',
        'isDigitalContract',],
      mapping: {
        supportedBy: 'Admin hỗ trợ',
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        rank: 'Cấp bậc',
        po: 'Project owner',
        appendixType: 'Bạn đang muốn làm gì?',
        appendixNumber: 'Số của Đơn đặt hàng/Phụ lục',
        contractValue_enterprise_1: 'Giá trị hợp đồng chưa bao gồm VAT (1)',
        contractValue_enterprise_2: 'VAT của hợp đồng (2)',
        contractValue_personal_1: 'Tổng số tiền đã bao gồm thuế thu nhập cá nhân (1)',
        contractValue_personal_2: 'Thuế thu nhập cá nhân (2)',
        appendixValue: 'Giá trị Đơn đặt hàng/Phụ lục',
        matched: 'Tìm thấy thông tin của đối tác trên hệ thống?',
        location: 'Khu vực (Theo khu vực của người lấy số Hợp đồng)',
        companyName: 'Công ty',
        information: 'Nội dung?',
        partners: 'Thông tin Đối tác',
        informationRequirement: 'Yêu cầu với sản phẩm này là gì?',
        informationProject: 'Sản phẩm này phục vụ cho Dự án nào?',
        informationTime: 'Thời gian hoàn thành Sản phẩm/Dịch vụ?',
        contractUrl: 'Link download hợp đồng mẫu',
        contractName: 'Loại hợp đồng',
        contractNumber: 'Số Hợp Đồng',
        partner: 'Đối tác bạn muốn ký hợp đồng',
        otherInformation: 'Các thông tin khác về hợp đồng nếu có',
        payment: 'Thanh toán',
        contractValue: 'Giá trị hợp đồng',
        pr: 'Link PR',
        claim: 'Purchase/ Claim number',
        includesGiftcode: 'Sản phẩm/Dịch vụ của bạn có Giftcode không?',
        partnerType: 'Loại đối tác',
        partnerName: 'Tên đối tác',
        partnerAlias: 'Tên viết tắt của đối tác',
        partnerTaxCode: 'Mã số thuế của đối tác',
        personalTaxCode: 'Mã số thuế cá nhân đối tác',
        signingDate: 'Thời gian ký kết Đơn đặt hàng/Phụ lục',
        duePaymentDate: 'Hạn thanh toán',
        paymentTerms: 'Hạn thanh toán',
        expiredDate: 'Ngày hết hạn Đơn đặt hàng/Phụ lục',
        isSponsorContract: 'Hợp đồng này có phải hợp đồng tài trợ/doanh thu không?',

        vnName: 'Tên đầy đủ bằng tiếng Việt (Full name in Vietnamese)',
        enName: 'Tên đầy đủ bằng tiếng Anh (Full name in English)',
        nationality: 'Quốc tịch (Nationality)',
        idCard: 'Số CMT/Căn cước công dân (Personal ID No.)',
        passport: 'Số Hộ chiếu (Passport No.)',
        dob: 'Ngày sinh (Date of Birth)',
        address: 'Địa chỉ bằng tiếng Anh (Address in English)',
        establishedLocation: 'Nơi thành lập (Country of Incorporation)',
        companyRegistration: 'Mã số đăng ký Công ty (Company Registration No.)',
        establishedDate: 'Ngày thành lập Công ty (Date of Incorporation)',
        game: 'Đơn đặt hàng/ Phụ lục này phục vụ công việc của Game/ Bộ phận gì?',
        hasTaxCode: 'Đối tác của bạn đã có mã số thuế (MST) chưa?',
        taxCode: 'Mã số thuế đối tác',
        enterpriseType: 'Form này dùng để tạo Đối tác mới trên hệ thống EBS, vui lòng cho biết Đối tác này:',
        category: 'Category',
        currency: 'Đơn vị tiền tệ',
        relative: 'Is the Vendor a relative or close friend of any employee of the Company? Đối tác có mối quan hệ họ hàng hoặc bạn thân của bất kỳ nhân viên nào trong Công ty không? Nếu có, vui lòng mô tả mối quan hệ này',
        accountName: 'Account Name (Tên tài khoản)',
        accountNo: 'Account Number (Số tài khoản)',
        bankName: 'Bank Name (Code) (Tên ngân hàng)',
        bankCode: 'Branch Name (Code) (Tên chi nhánh)',
        partnerStatus: 'Trạng thái phê duyệt đối tác mới',
        partnerTicket: 'Link ticket phê duyệt đối tác mới trên Gigi',
        isDigitalContract: 'Hợp đồng này có thực hiện ký bằng Chữ ký Số hay không? ',

      },
      description: {
        supportedBy: `Admin hỗ trợ là người sẽ giúp bạn thực hiện hợp đồng, thanh toán. Nếu chưa rõ, vui lòng liên hệ honghanh.nguyen@garena.vn để được giải đáp.`,
        isDigitalContract: `
        Hợp đồng ký bằng <a href="https://gigi.garena.vn/article/322" target="_blank">Chữ ký Số</a> là có nghĩa là 2 bên sử dụng USB token đã được đăng ký và ký hoàn toàn trên nền tảng file PDF đã có đầy đủ chữ ký Online của Công ty mà KHÔNG CẦN phải in ra bản cứng (giấy) để Công ty ký đóng dấu sau đó đưa cho Đối tác ký đóng dấu như cách thông thường trước đó. <br/>Để thực hiện được hình thức này, vui lòng liên hệ Đối tác để nắm được thông tin. <br/>Nếu chưa rõ về vấn đề Chữ ký Số, cần liên hệ Legal phụ trách trước khi thực hiện Hợp đồng
        `,
        game: `<span class="hl">Quan trọng:</span> Khi bạn chọn Game/ Bộ phận nào thì <strong>Người phụ trách (PM) của team đó sẽ được tự động thêm vào 
        Ticket</strong> sau khi submit cũng như là người chịu trách nhiệm <strong>ký online</strong> cùng lên toàn bộ giấy tờ từ <strong>Hợp đồng, 
        Phụ lục, Đơn đặt hàng, Biên bản nghiệm thu</strong> và các giấy tờ khác nếu có`,
        isSponsorContract: 'Định nghĩa: Hợp đồng tài trợ/ doanh thu là hợp đồng trong đó Đối tác tài trợ cho công ty để đổi lại các quyền lợi tương ứng.',
        appendixNumber: `
        <strong>Có</strong>: Vui lòng bỏ trống<br/>
        <strong>Không</strong>: Vui lòng điền số của Đơn đặt hàng
        `,
        information: 'VD: Sản xuất video 3D/CGI cho dự án Sổ Sứ Mệnh Valentine VP26',
        informationRequirement: `VD: <br/>Chất lượng:        Tối thiểu là Full HD/4K <br/>
        Định dạng:        MP4<br/>
        Thời lượng:        40 giây - 1 phút<br/>
        Môi trường:        1 bối cảnh duy nhất (phòng thí nghiệm)<br/>
        Nhân vật:        4 nhân vật cần độ model: 2 nhân vật chính và 2 nhân vật phụ<br/>
        Chuyển động:        Các cảnh chiến đấu chuyển động nhiều<br/>
        Hiệu ứng:        Hiệu ứng cháy nổ trong phòng`,
        informationProject: 'VD: Tết Âm lịch/ Tết Dương lịch',
        informationTime: 'VD: 12/12/2020 là ngày bàn giao file/thiết kế hoàn chỉnh',
        taxCode: `<ul>
        <li>Vui lòng hỏi cá nhân ký hợp đồng, sau đó vào 
  <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> để tra cứu xem Mã số thuế cá nhân đã chính xác và khớp với Số chứng minh thư/ Thẻ căn cước của cá nhân chưa</li>
  <li>Nếu cá nhân ký hợp đồng không biết Mã số thuế cá nhân của họ, bạn vui lòng vào trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> điền Số chứng minh thư/Thẻ căn cước của cá nhân để lấy được Mã số thuế cá nhân.</li>
  <li>Nếu tìm trong trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> không có kết quả, vui lòng điền Chưa có</li>
  <li>Chi tiết cách tra cứu xem <a href="https://drive.google.com/file/d/1WGU_n9p0jtl6f7TnrNTmduhiz-MAGB_g/view" target="_blank">tại đây</a></li>
  <li class="hl">Mã số thuế cá nhân là Bắt buộc</li>
  <li>Nếu tìm trong trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> và làm theo Hướng dẫn không có kết quả, vui lòng điền Chưa có đồng thời Download file bao gồm <a href="https://docs.google.com/document/d/1X34soNqAXD5Tmn9XGQMsMybNjQzceh9s/edit" target="_blank"> Giấy Ủy quyền đăng ký thuế thu nhập cá nhân và Tờ khai đăng ký thuế </a> yêu cầu Cá nhân điền, ký và <span class="hl">nộp kèm Photo CMTND/CCCD</span> cho team Nhân sự (Contact point: ngoctram.nguyen@garena.vn)</li>
  </ul>`,
        enName: `Truy cập trang <a href="https://dangkykinhdoanh.gov.vn/" target="_blank">dangkykinhdoanh.gov.vn</a>, sử dụng mã số đăng ký Công ty để tìm tên tiếng Anh đúng theo đăng ký (nếu có).`,
        enNamePersonal: `Đảo trật tự tên theo đúng yêu cầu. Ví dụ:<br/> Vũ Ngọc Mai: Mai Ngoc Vu. <br/>Trần Thị Minh Trang: Trang Thi Minh Tran`,
        establishedDate: `Lưu ý: Truy cập trang: <a href="https://dangkykinhdoanh.gov.vn/" target="_blank">dangkykinhdoanh.gov.vn</a>, sử dụng mã số đăng ký Công ty để tìm ngày thành lập Công ty chính xác`,
      }
    },
    29: {
      formName: 'Đăng ký nghỉ phép dự trù',
      order: ['fullname', 'email', 'dayCnt', 'records'],
      approveCenterColumns: ['records'],
      records_order: ['start_date', 'end_date', 'duration'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        game: 'Bộ phận của bạn',
        po: 'Project Owner',
        records: 'Ngày đăng ký nghỉ phép',
        start_date: 'Từ ngày',
        end_date: 'Đến ngày',
        duration: 'Số ngày',
        dayCnt: 'Tổng số ngày đăng ký'
      },
      fixContent: {
      },
      description: {
        trips: ``,
      }
    },
    30: {
      formName: 'Hợp đông cung ứng dịch vụ theo từng vụ việc ',
      order: [],
      tableFields: [],
      tableFieldsHidden: ['Email', 'gigiTicketId'],
      fieldsHidden: ['signingDay', 'trips'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        game: 'Bộ phận của bạn',
        po: 'Project Owner',
        contractNumber: 'Số Hợp đồng',
        signingDate: 'Ngày ký',
        uqInfo: 'Giấy ủy quyền',
        signerPosition: 'Chức vụ người ký',
        partnerName: 'Bên B',
        partnerAddress: 'Địa chỉ bên B',
        taxCode: 'Mã số thuế bên B',
        partnerPresentorGender: 'Giới tính đại diện bên B',
        partnerPresentor: 'Đại diện bên B',
        partnerPresentorPosition: 'Chức vụ Đại diện bên B',
        uqPartnerInfo: 'Giấy ủy quyền bên B',
        partnerBank: 'Tài khoản bên B',
        partnerIdCard: 'CMND/CCCD bên B',
        partnerIdCardIssuedDate: 'Ngày cấp CMND/CCCD bên B',
        partnerIdCardIssuedLocation: 'Nơi cấp CMND/CCCD bên B',
        dichvuTableCongThanhTien: 'Dịch vụ cộng thành tiền',
        gtgt: 'Thuế giá trị gia tăng',
        dichvuTable: 'Bảng dịch vụ',
        chatluongDichvu: 'Bảng chất lượng dịch vụ',
        dichvuTableThue: 'Thuế',
        dichvuTableTongCong: 'Dịch vụ tổng cộng ',
        dichvuTableTongCongBangChu: 'Dịch vụ tổng cộng bằng chữ',
        thoiHanThanhToan: 'Thời hạn thanh toán',
        soDotThanhToan: 'Số đợt thanh toán',
        chiTietThanhToan: 'Chi tiết thanh toán',
        thoiHanKhongCanhTranhNo: 'Không Thời hạn không cạnh tranh',
        thoiHanKhongCanhTranhYes: 'Có Thời hạn không cạnh tranh',
        chiTietThoiHanKhongCanhTranh: 'Chi tiết thời hạn không cạnh tranh',
        chiTietQuyenVaNghiaVu: 'Chi tiết quyền và nghĩa vụ',
      },
      fixContent: {
      },
      description: {
        trips: ``,
      }
    },
    31: {
      formName: 'Biên bản nghiệm thu',
      order: [],
      tableFields: ['trips'],
      tableFieldsHidden: ['Email', 'gigiTicketId'],
      fieldsHidden: ['signingDay', 'trips'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        game: 'Bộ phận của bạn',
        po: 'Project Owner',
      },
      fixContent: {
      },
      description: {
        trips: ``,
      }
    },
    32: {
      formName: 'QDNB về việc tổ chức GĐTTDT hoặc sự kiện cuộc thi cộng đồng',
      order: [],
      tableFields: ['programGoods', 'programStructure', 'equipment', 'programDetail'],
      fieldsHidden: ['signingDay',],
      noPrint: ['game'],
      inlineStyle: {
        fix_0: {
          float: 'left',
          borderRadius: '0rem',
          width: '40%',
          textAlign: 'center'
        },
        fix_1: {
          float: 'right',
          borderRadius: '0rem',
          width: '50%',
          marginTop: '-7rem',
        },
        'signingDay': {
          fontWeight: 'normal',
          fontStyle: 'italic',
          width: '100%',
          textAlign: 'right',
          borderRadius: '0rem'
        },
        'fix_5': {
          maxWidth: '28rem',
          width: '28rem',
          float: 'right',
        }
      },
      mapping: {
        formType: 'QĐNB về việc tổ chức',
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        game: 'Bộ phận của bạn',
        po: 'Project Owner',
        programName: 'Tên chương trình',
        gameName: 'Tên sản phẩm',
        targetAudition: 'Đối tượng tham gia',
        programLevel: 'Quy mô giải đấu',
        online: 'Hình thức tổ chức',
        docType: 'Loại Giấy tờ',
        location: 'Địa điểm tổ chức',
        timeRange: 'Thời gian điễn ra giải đấu',
        programStructure: 'Cơ cấu giải (nội dung giải, giá trị giải và số lượng giải)',
        equipment: 'Trang thiết bị được cấp phát cho đối tượng tham gia',
        programAmount: 'Tổng chi phí dự kiến',
        signingDay: 'Ngày ký Quyết Định',
        programDetail: 'Chi tiết của chương trình',
        pr: 'Link PR',
        claim: 'Purchase/ Claim number',
        hasReward: 'Bạn có hàng hóa cho tặng, giải thưởng tiền mặt cho khán giả đến tham dự tại sự kiện và Tổng giá trị hàng hóa, giải thưởng này dưới 100 triệu VNĐ không?',
        rewardType: 'Hình thức khuyến mại',
        rewardTimeRange: 'Thời gian khuyến mại',
        rewardGoods: 'Hàng hoá, dịch vụ dùng để khuyến mại',
        rewardProgramStructure: 'Cơ cấu giải thưởng và cách thức trao thưởng (nội dung giải thưởng, giá trị giải thưởng, số lượng giải thưởng)',
        rewardAmount: 'Tổng giá trị hàng hóa, dịch vụ dùng để khuyến mại',
        rewardDetail: 'Nội dung chi tiết của chương trình khuyến mại',
      },
      description: {
        location: `Điền tên tỉnh/thành phố nơi thực hiện khuyến mãi.<br/>Nếu tổ chức tại nhiều tỉnh thì ghi tên từng tỉnh. <br/>Nếu tổ chức trực tuyến thì ghi Toàn quốc`,
        equipment: `Liệt kê các loại trang thiết bị được cấp phát ví dụ như chuột, bàn phím, quần áo, giày dép, 
        trang phục khác ..... kèm số lượng, đơn giá, tổng giá trị. <br/>
        <span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span><br/>`,
        programGoods: `Liệt kê các loại hàng hóa, dịch vụ (nếu có). Tổng giá trị phải dưới 100 triệu. 
        Nếu tròn 100 triệu, phải thực hiện thủ tục khác, vui lòng xem hướng dẫn tại đây <a href="https://gigi.garena.vn/article/129" target="_blank">tại đây </a>
        <br/><br/>
        <strong>
        Công ty chịu toàn bộ chi phí bao gồm thuế thu nhập cá nhân(TNCN) cho người nhận giải thưởng Hiện vật 
        (Khách hàng), các bạn lưu ý cách tính chi tiết TỪNG SỰ KIỆN để ghi vào Thông báo khuyến mãi 
        / Quyết định nội bộ/ lên PR như trong <a href="https://docs.google.com/spreadsheets/d/1m-G_2vlzuMJpVWIZ3A3xA_WPG6FBvswLARS3Ymt52V4/edit#gid=522722066" target="_blank">file sau</a> (các tab PIT Giải thưởng hiện vật) 
        <br/><span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span><br/>
        </strong>`,
        programStructure: `Mô tả cơ cấu quà tặng
        <br/>
        <strong>
        Công ty chịu toàn bộ chi phí bao gồm thuế thu nhập cá nhân(TNCN) cho người nhận giải thưởng Hiện vật 
        (Khách hàng), các bạn lưu ý cách tính chi tiết TỪNG SỰ KIỆN để ghi vào Thông báo khuyến mãi 
        / Quyết định nội bộ/ lên PR như trong <a href="https://docs.google.com/spreadsheets/d/1m-G_2vlzuMJpVWIZ3A3xA_WPG6FBvswLARS3Ymt52V4/edit#gid=522722066" target="_blank">file sau</a> (các tab PIT Giải thưởng hiện vật) 
        <br/><span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span>
        </strong>`,
        programDetail: `<span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span><br/>`,
        rewardGoods: `<span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span><br/>`,
        rewardProgramStructure: `<span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span><br/>`,
        rewardDetail: `<span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span><br/>`
      }
    },
    33: {
      formName: 'Quyết định nội bộ',
      order: [],
      tableFields: ['programGoods', 'programStructure'],
      fieldsHidden: ['signingDay',],
      noPrint: ['game'],
      inlineStyle: {
        fix_0: {
          float: 'left',
          borderRadius: '0rem',
          width: '40%',
          textAlign: 'center'
        },
        fix_1: {
          float: 'right',
          borderRadius: '0rem',
          width: '50%',
          marginTop: '-7rem',
        },
        'signingDay': {
          fontWeight: 'normal',
          fontStyle: 'italic',
          width: '100%',
          textAlign: 'right',
          borderRadius: '0rem'
        },
        'fix_5': {
          maxWidth: '28rem',
          width: '28rem',
          float: 'right',
        }
      },
      mapping: {
        formType: 'Quyết định nội bộ về việc',
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        game: 'Bộ phận của bạn',
        po: 'Project Owner',
        programName: 'Tên chương trình',
        docType: 'Loại Giấy tờ',
        programStructure: 'Danh sách quà hoặc danh sách nhân viên tham gia',
        signingDay: 'Ngày ký Quyết Định',
        programDetail: 'Chi tiết của chương trình',
        pr: 'Link PR',
        claim: 'Purchase/ Claim number',
        company: 'Quyết định nội bộ dành cho công ty',
        veviec: 'Nội dung sẽ hiển thị trên văn bản',
        dieu1: 'Nội dung điều 1'
      },
      description: {
        veviec: `<img src="https://g.cache.garena.vn/bns/uploads/56acf6d7-85e8-4d99-bcaf-5e28ec22b58e.png" width="100%"/>`,
        dieu1: `<img src="https://g.cache.garena.vn/bns/uploads/4ef44f1a-f8a7-4b5b-947a-293b21f814c6.png" width="100%"/>`,
        programStructure: ` 
        <br/><span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span>
        </strong>`,
      }
    },
    34: {
      formName: 'Bundle Đơn đặt hàng/Phụ lục/Biên bản thanh lý hợp đồng',
      order: ['fullname', 'email', 'phone', 'po', 'supportedBy', 'game', 'isSponsorContract', 'hasFile', 'poSigned', 'pmSigned', 'legalSigned',
        'financeSigned', 'currentStatus', 'noOfRejected', 'appendixType', 'partners',
        'currency', 'contractName', 'contractUrl', 'location', 'partnerType', 'partner', 'vnName', 'partnerTaxCode', 'idCard', 'personalTaxCode',
        'information', 'informationRequirement', 'informationProject', 'informationTime', 'claim', 'pr', 'payment',
        'signingDate', 'paymentTerms', 'duePaymentDate', 'includesGiftcode', 'otherInformation'],
      highlight: ['isSponsorContract', 'appendixNumber', 'matched', 'partnerStatus', 'partnerTicket', 'includesGiftcode',
        'isDigitalContract',],
      partners_order: ['contractNumber', 'pr', 'appendixValue', 'appendixNumber'],
      mapping: {
        supportedBy: 'Admin hỗ trợ',
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        rank: 'Cấp bậc',
        po: 'Project owner',
        appendixType: 'Bạn đang muốn làm gì?',
        appendixNumber: 'Số của Đơn đặt hàng/Phụ lục',
        contractValue_enterprise_1: 'Giá trị hợp đồng chưa bao gồm VAT (1)',
        contractValue_enterprise_2: 'VAT của hợp đồng (2)',
        contractValue_personal_1: 'Tổng số tiền đã bao gồm thuế thu nhập cá nhân (1)',
        contractValue_personal_2: 'Thuế thu nhập cá nhân (2)',
        appendixValue: 'Giá trị Đơn đặt hàng/Phụ lục',
        matched: 'Tìm thấy thông tin của đối tác trên hệ thống?',
        location: 'Khu vực (Theo khu vực của người lấy số Hợp đồng)',
        companyName: 'Công ty',
        information: 'Nội dung?',
        informationRequirement: 'Yêu cầu với sản phẩm này là gì?',
        informationProject: 'Sản phẩm này phục vụ cho Dự án nào?',
        informationTime: 'Thời gian hoàn thành Sản phẩm/Dịch vụ?',
        contractUrl: 'Link download hợp đồng mẫu',
        contractName: 'Loại hợp đồng',
        contractNumber: 'Số Hợp Đồng',
        otherInformation: 'Các thông tin khác về hợp đồng nếu có',
        payment: 'Thanh toán',
        contractValue: 'Giá trị hợp đồng',
        pr: 'Link PR',
        claim: 'Purchase/ Claim number',
        includesGiftcode: 'Sản phẩm/Dịch vụ của bạn có Giftcode không?',
        partnerType: 'Loại đối tác',
        partnerName: 'Tên đối tác',
        partnerAlias: 'Tên viết tắt của đối tác',
        partnerTaxCode: 'Mã số thuế của đối tác',
        personalTaxCode: 'Mã số thuế cá nhân đối tác',
        signingDate: 'Thời gian ký kết Đơn đặt hàng/Phụ lục/Biên bản thanh lý hợp đồng',
        duePaymentDate: 'Hạn thanh toán',
        paymentTerms: 'Hạn thanh toán',
        expiredDate: 'Ngày hết hạn Đơn đặt hàng/Phụ lục/Biên bản thanh lý hợp đồng',
        isSponsorContract: 'Hợp đồng này có phải hợp đồng tài trợ/doanh thu không?',
        partners: 'Thông tin đối tác',
        vnName: 'Tên đầy đủ bằng tiếng Việt (Full name in Vietnamese)',
        enName: 'Tên đầy đủ bằng tiếng Anh (Full name in English)',
        nationality: 'Quốc tịch (Nationality)',
        idCard: 'Số CMT/Căn cước công dân (Personal ID No.)',
        passport: 'Số Hộ chiếu (Passport No.)',
        dob: 'Ngày sinh (Date of Birth)',
        address: 'Địa chỉ bằng tiếng Anh (Address in English)',
        establishedLocation: 'Nơi thành lập (Country of Incorporation)',
        companyRegistration: 'Mã số đăng ký Công ty (Company Registration No.)',
        establishedDate: 'Ngày thành lập Công ty (Date of Incorporation)',
        game: 'Đơn đặt hàng/Phụ lục/Biên bản thanh lý hợp đồng này phục vụ công việc của Game/ Bộ phận gì?',
        hasTaxCode: 'Đối tác của bạn đã có mã số thuế (MST) chưa?',
        taxCode: 'Mã số thuế đối tác',
        enterpriseType: 'Form này dùng để tạo Đối tác mới trên hệ thống EBS, vui lòng cho biết Đối tác này:',
        category: 'Category',
        currency: 'Đơn vị tiền tệ',
        relative: 'Is the Vendor a relative or close friend of any employee of the Company? Đối tác có mối quan hệ họ hàng hoặc bạn thân của bất kỳ nhân viên nào trong Công ty không? Nếu có, vui lòng mô tả mối quan hệ này',
        accountName: 'Account Name (Tên tài khoản)',
        accountNo: 'Account Number (Số tài khoản)',
        bankName: 'Bank Name (Code) (Tên ngân hàng)',
        bankCode: 'Branch Name (Code) (Tên chi nhánh)',
        partnerStatus: 'Trạng thái phê duyệt đối tác mới',
        partnerTicket: 'Link ticket phê duyệt đối tác mới trên Gigi',
        isDigitalContract: 'Hợp đồng này có thực hiện ký bằng Chữ ký Số hay không? ',
      },
      description: {
        supportedBy: `Admin hỗ trợ là người sẽ giúp bạn thực hiện hợp đồng, thanh toán. Nếu chưa rõ, vui lòng liên hệ honghanh.nguyen@garena.vn để được giải đáp.`,
        isDigitalContract: `
        Hợp đồng ký bằng <a href="https://gigi.garena.vn/article/322" target="_blank">Chữ ký Số</a> là có nghĩa là 2 bên sử dụng USB token đã được đăng ký và ký hoàn toàn trên nền tảng file PDF đã có đầy đủ chữ ký Online của Công ty mà KHÔNG CẦN phải in ra bản cứng (giấy) để Công ty ký đóng dấu sau đó đưa cho Đối tác ký đóng dấu như cách thông thường trước đó. <br/>Để thực hiện được hình thức này, vui lòng liên hệ Đối tác để nắm được thông tin. <br/>Nếu chưa rõ về vấn đề Chữ ký Số, cần liên hệ Legal phụ trách trước khi thực hiện Hợp đồng
        `,
        game: `<span class="hl">Quan trọng:</span> Khi bạn chọn Game/ Bộ phận nào thì <strong>Người phụ trách (PM) của team đó sẽ được tự động thêm vào 
        Ticket</strong> sau khi submit cũng như là người chịu trách nhiệm <strong>ký online</strong> cùng lên toàn bộ giấy tờ từ <strong>Hợp đồng, 
        Phụ lục, Đơn đặt hàng, Biên bản nghiệm thu</strong> và các giấy tờ khác nếu có`,
        isSponsorContract: 'Định nghĩa: Hợp đồng tài trợ/ doanh thu là hợp đồng trong đó Đối tác tài trợ cho công ty để đổi lại các quyền lợi tương ứng.',
        appendixNumber: `
        <strong>Có</strong>: Vui lòng bỏ trống<br/>
        <strong>Không</strong>: Vui lòng điền số của Đơn đặt hàng
        `,
        information: 'VD: Sản xuất video 3D/CGI cho dự án Sổ Sứ Mệnh Valentine VP26',
        informationRequirement: `VD: <br/>Chất lượng:        Tối thiểu là Full HD/4K <br/>
        Định dạng:        MP4<br/>
        Thời lượng:        40 giây - 1 phút<br/>
        Môi trường:        1 bối cảnh duy nhất (phòng thí nghiệm)<br/>
        Nhân vật:        4 nhân vật cần độ model: 2 nhân vật chính và 2 nhân vật phụ<br/>
        Chuyển động:        Các cảnh chiến đấu chuyển động nhiều<br/>
        Hiệu ứng:        Hiệu ứng cháy nổ trong phòng`,
        informationProject: 'VD: Tết Âm lịch/ Tết Dương lịch',
        informationTime: 'VD: 12/12/2020 là ngày bàn giao file/thiết kế hoàn chỉnh',
        taxCode: `<ul>
        <li>Vui lòng hỏi cá nhân ký hợp đồng, sau đó vào 
  <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> để tra cứu xem Mã số thuế cá nhân đã chính xác và khớp với Số chứng minh thư/ Thẻ căn cước của cá nhân chưa</li>
  <li>Nếu cá nhân ký hợp đồng không biết Mã số thuế cá nhân của họ, bạn vui lòng vào trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> điền Số chứng minh thư/Thẻ căn cước của cá nhân để lấy được Mã số thuế cá nhân.</li>
  <li>Nếu tìm trong trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> không có kết quả, vui lòng điền Chưa có</li>
  <li>Chi tiết cách tra cứu xem <a href="https://drive.google.com/file/d/1WGU_n9p0jtl6f7TnrNTmduhiz-MAGB_g/view" target="_blank">tại đây</a></li>
  <li class="hl">Mã số thuế cá nhân là Bắt buộc</li>
  <li>Nếu tìm trong trang <a href="http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp" target="_blank">http://tracuunnt.gdt.gov.vn/tcnnt/mstcn.jsp</a> và làm theo Hướng dẫn không có kết quả, vui lòng điền Chưa có đồng thời Download file bao gồm <a href="https://docs.google.com/document/d/1X34soNqAXD5Tmn9XGQMsMybNjQzceh9s/edit" target="_blank"> Giấy Ủy quyền đăng ký thuế thu nhập cá nhân và Tờ khai đăng ký thuế </a> yêu cầu Cá nhân điền, ký và <span class="hl">nộp kèm Photo CMTND/CCCD</span> cho team Nhân sự (Contact point: ngoctram.nguyen@garena.vn)</li>
  </ul>`,
        enName: `Truy cập trang <a href="https://dangkykinhdoanh.gov.vn/" target="_blank">dangkykinhdoanh.gov.vn</a>, sử dụng mã số đăng ký Công ty để tìm tên tiếng Anh đúng theo đăng ký (nếu có).`,
        enNamePersonal: `Đảo trật tự tên theo đúng yêu cầu. Ví dụ:<br/> Vũ Ngọc Mai: Mai Ngoc Vu. <br/>Trần Thị Minh Trang: Trang Thi Minh Tran`,
        establishedDate: `Lưu ý: Truy cập trang: <a href="https://dangkykinhdoanh.gov.vn/" target="_blank">dangkykinhdoanh.gov.vn</a>, sử dụng mã số đăng ký Công ty để tìm ngày thành lập Công ty chính xác`,
      }
    },
    35: {
      formName: 'Bảng tổng kết giải thưởng',
      order: [],
      tableFields: ['programGoods', 'programStructure'],
      fieldsHidden: ['signingDay',],
      noPrint: ['game'],
      inlineStyle: {
        fix_0: {
          float: 'left',
          borderRadius: '0rem',
          width: '40%',
          textAlign: 'center'
        },
        fix_1: {
          float: 'right',
          borderRadius: '0rem',
          width: '50%',
          marginTop: '-7rem',
        },
        'signingDay': {
          fontWeight: 'normal',
          fontStyle: 'italic',
          width: '100%',
          textAlign: 'right',
          borderRadius: '0rem'
        },
        'fix_5': {
          maxWidth: '28rem',
          width: '28rem',
          float: 'right',
        }
      },
      mapping: {
        formType: 'Quyết định nội bộ về việc',
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        game: 'Bộ phận của bạn',
        po: 'Project Owner',
        eventName: 'Tên đầy đủ sự kiện',
        eventTime: 'Thời gian tổ chức',
        programStructure: 'Kết quả trúng giải',
        paymentType: 'Hình thức nhận',
        signingDay: 'Ngày ký Quyết Định',
        pr: 'Link PR',
        claim: 'Purchase/ Claim number',
      },
      description: {
        programStructure: ` 
        <br/><span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span>
        </strong>`,
      }
    },
    36: {
      formName: 'Đăng ký thông tin nhân viên mới',
      order: ['fullname', 'location', 'startDate', 'endDate', 'name', 'team', 'position', 'reportingManager',
        'personalEmail', 'phone', 'dob', 'address',
        'createCompanyEmail', 'existingEmail', 'emailNote', 'vehicalInfo', 'note'],
      highlight: ['location', 'startDate', 'team'],
      mapping: {
        fullname: 'Họ và tên người đăng ký',
        internCsvInfo: 'Thông tin nhân viên dưới dạng bảng',
        contractNo: 'Số hợp đồng',
        probation: 'Thời gian thử việc',
        email: 'Email người đăng ký',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        name: 'Họ và tên của nhân viên',
        personalEmail: 'Email cá nhân của nhân viên',
        benefit: 'Các dịch vụ nội bộ cần hỗ trợ',
        vehicalInfo: 'Thông tin phương tiện đi lại',
        bankAccount: 'Số tài khoản ngân hàng',
        bank: 'Tên Ngân hàng',
        branch: 'Chi nhánh',
        position: 'Vị trí công việc',
        location: 'Địa điểm làm việc',
        address: 'Địa chỉ',
        team: 'Bộ phận',
        dob: 'Ngày tháng năm sinh',
        cmnd: 'CMND',
        cmndIssuedDate: 'Ngày cấp CMND ',
        cmndIssuedLocation: 'Nơi cấp CMND ',
        cccd: 'CCCD ',
        cccdIssuedDate: 'Ngày cấp CCCD ',
        cccdIssuedLocation: 'Nơi cấp CCCD ',
        phone: 'Điện thoại ',
        startDate: 'Thời gian bắt đầu công việc (tính theo thời gian trên HĐ)',
        endDate: 'Thời gian dự kiến kết thúc thử việc',
        allowance: 'Trợ cấp kèm theo',
        internType: 'Loại Hợp đồng',
        salaryType: 'Phương thức trả lương',
        education: 'Trình độ văn hoá',
        internManager: 'Email quản lý trực tiếp (báo cáo công việc trực tiếp)',
        taxCode: 'Mã số thuế cá nhân',
        hasTaxCode: 'Nhân viên đã có mã số thuế (MST) chưa?',
        createCompanyEmail: 'Cần tạo mới Email Công ty?',
        reportingManager: 'Quản lý trực tiếp',
        existingEmail: 'Email hiện tại đang có',
        emailNote: 'Cách điều chỉnh Email'
      },
      description: {
        taxCode: `<ul>
        <li>Chi tiết cách tra cứu xem <a href="https://drive.google.com/file/d/1WGU_n9p0jtl6f7TnrNTmduhiz-MAGB_g/view" target="_blank">tại đây</a></li>
        <li class="hl">Mã số thuế cá nhân là Bắt buộc</li></ul>
        `,
        taxCodeWarning: ``,
      }
    },
    37: {
      formName: 'Thư mời',
      order: [],
      tableFields: ['programGoods', 'programStructure', 'participants'],
      fieldsHidden: ['signingDay',],
      noPrint: ['game'],
      inlineStyle: {
        fix_0: {
          float: 'left',
          borderRadius: '0rem',
          width: '40%',
          textAlign: 'center'
        },
        fix_1: {
          float: 'right',
          borderRadius: '0rem',
          width: '50%',
          marginTop: '-7rem',
        },
        'signingDay': {
          fontWeight: 'normal',
          fontStyle: 'italic',
          width: '100%',
          textAlign: 'right',
          borderRadius: '0rem'
        },
        'fix_5': {
          maxWidth: '28rem',
          width: '28rem',
          float: 'right',
        }
      },
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        game: 'Bộ phận của bạn',
        po: 'Project Owner',
        dataTicketId: 'Thư mời này liên quan đến ticket booking nào? Điền số ticket',
        event: 'Event',
        program: 'Program',
        effectiveDate: 'Effecttive Date',
        expirationDate: 'Expiration Date',
        location: 'Location',
        participants: 'Participants',
        pr: 'Link PR',
        claim: 'Purchase/ Claim number',
        cost: 'Fixed cost'
      },
      description: {
        participants: ` 
        <br/><span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span>
        </strong>`,
      }
    },
    38: {
      formName: 'Yêu cầu xuất kho',
      order: ['fullname', 'email', 'staffId', 'game', 'location',
        'v2GoodType', 'givingFormat', 'givingTypeCb', 'goodType', 'plannedGoods', 'event',
        'qdnb', 'qdnbGigi', 'deliveryDate', 'deliveryFinishDate', 'deliveryAddress', 'note'],
      tableFields: ['goodType', 'plannedGoods'],
      tableFieldsHidden: [],
      fieldsHidden: [],
      mapping: {
        game: 'Bộ phận',
        location: 'Địa điểm kho',
        goodType: 'Loại hàng hóa và số lượng cần xuất kho',
        event: 'Tên sự kiện/ Mục đích sử dụng khác',
        qdnb: 'Số QĐNB/TBKM',
        qdnbGigi: 'Link Gigi cuả QĐNB/TBKM',
        deliveryDate: 'Ngày cần nhận hàng',
        deliveryFinishDate: 'Thời gian kết thúc trao tặng',
        deliveryAddress: 'Địa điểm nhận hàng',
        note: 'Ghi chú khác (nếu có)',
        v2GoodType: 'Loại hàng hóa cần xuất kho',
        givingFormat: 'Hình thức trao',
        plannedGoods: 'Danh sách trao tặng dự kiến',
      },
      description: {
        goodType: ` 
        <br/><span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span>
        </strong>`,
        plannedGoods: ` 
        <br/><span class="hl">Lưu ý: Có thể dán (paste) dưới dạng bảng kê. Chú ý không sử dụng cột/dòng merge</span>
        </strong>`
      }
    },
    39: {
      formName: 'Thông báo nhân viên nghỉ việc',
      order: ['fullname', 'email', 'staffId', 'location', 'offboardStaffId', 'offboardStaffEmail', 'offboardStaffName', 'offboardStaffManagerEmail', 'game', 'lastDate', 'note'],
      tableFields: [],
      tableFieldsHidden: [],
      fieldsHidden: [],
      mapping: {
        game: 'Bộ phận',
        location: 'Khu vực',
        offboardStaffId: 'ID nhân viên nghỉ việc',
        offboardStaffEmail: 'Email nhân viên nghỉ việc',
        offboardStaffManagerEmail: 'Email quản lý trực tiếp',
        offboardStaffName: 'Họ tên nhân viên nghỉ việc',
        lastDate: 'Ngày làm việc cuối cùng',
        note: 'Ghi chú khác (nếu có)',
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã nhân viên',
      },
      description: {
      }
    },
    40: {
      formName: 'Đăng ký ủy quyền Quyết toán thuế TNCN',
      order: ['fullname', 'email', 'staffId', 'company', 'taxCode', 'idCard', 'grantPermission', 'uq1Image', 'uq2Image'],
      tableFields: ['trips'],
      tableFieldsHidden: ['Email', 'gigiTicketId'],
      fieldsHidden: ['signingDay', 'trips'],
      mapping: {
        fullname: 'Họ và Tên',
        email: 'Email',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        company: 'Anh/Chị đang được ký Hợp đồng lao động với Công ty',
        taxCode: 'Mã số thuế cá nhân',
        idCard: 'Số Chứng minh thư nhân dân/thẻ Căn cước công dân (Số CMTND gắn liền với Mã số thuế)',
        uq1Image: `Đính kèm bản chụp Giấy ủy quyền Quyết toán thuế TNCN (đã ký) - Trong một file`,
        uq2Image: `Đính kèm bản chụp Giấy ủy quyền Quyết toán thuế TNCN (đã ký) - Trang 2`,
        grantPermission: 'Anh/Chị có ủy quyền quyết toán thuế thu nhập cá nhân năm 2024 cho Công ty không?',
      },
      description: {
        taxCode: `Tra cứu tại trang: <a href="https://people.ved.com.vn/" target="_blank">people.ved.com.vn</a> - mục Tax ID`,
        staffId: `Tra cứu tại trang: <a href="https://people.ved.com.vn/" target="_blank">people.ved.com.vn</a> - mục Staff ID`,
        uq1Image: `
        Vui lòng download mẫu uỷ quyền <a href="https://docs.google.com/document/d/1Di9_s4wraH3xAXFVzKSWHFTjYCH8Z1fg0ggJwW_hv3w/edit" target="_blank">tại đây</a>.<br/><br/>
        Yêu cầu không nhờ người ký thay, nếu Cơ quan Thuế phát hiện sai sót trong việc khai báo, 
        cá nhân người lao động chịu hoàn toàn trách nhiệm trước Pháp luật và Công ty.
        <br/><br/>
        Thông tin Mã số thuế Công ty dùng để điền Giấy ủy quyền
        <table>
          <tbody>
            <tr>
              <td>CÔNG TY CỔ PHẦN GIẢI TRÍ VÀ THỂ THAO ĐIỆN TỬ VIỆT NAM</td>
              <td>0105301438</td>
            </tr>
            <tr>
              <td>Công ty TNHH dịch vụ phần mềm Hòa Thái</td>
              <td>2801724858</td>
            </tr>
            <tr>
              <td>CÔNG TY TNHH DỊCH VỤ PHẦN MỀM THIÊN BÌNH</td>
              <td>0106803215</td>
            </tr>
            <tr>
              <td>CÔNG TY TNHH DỊCH VỤ PHẦN MỀM BÌNH MINH</td>
              <td>0106803303</td>
            </tr>
          <tbody>
        </table>
        <br/><br/>
        `
      }
    },
    41: {
      formName: 'Chào đón nhân viên mới',
      order: ['fullname', 'internCsvInfo', 'onboardingDatetime', 'location', 'team',
        'onboardingAgenda', 'name', 'workEmail', 'reportingManager',
        'phone', 'dob', 'address', 'createCompanyEmail', 'existingEmail',
        'emailNote', 'vehicalInfo', 'note'],
      highlight: ['location', 'onboardingDatetime', 'team'],
      mapping: {
        fullname: 'Họ và tên người đăng ký',
        internCsvInfo: 'Thông tin nhân viên dưới dạng bảng',
        contractNo: 'Số hợp đồng',
        probation: 'Thời gian thử việc',
        workEmail: 'Email công ty',
        email: 'Email người đăng ký',
        phone: 'Số điện thoại',
        staffId: 'Mã số nhân viên',
        name: 'Họ và tên của nhân viên',
        personalEmail: 'Email cá nhân của nhân viên',
        benefit: 'Các dịch vụ nội bộ cần hỗ trợ',
        vehicalInfo: 'Thông tin phương tiện đi lại',
        bankAccount: 'Số tài khoản ngân hàng',
        bank: 'Tên Ngân hàng',
        branch: 'Chi nhánh',
        position: 'Vị trí công việc',
        location: 'Địa điểm làm việc',
        address: 'Địa chỉ',
        team: 'Bộ phận',
        dob: 'Ngày tháng năm sinh',
        cmnd: 'CMND',
        cmndIssuedDate: 'Ngày cấp CMND ',
        cmndIssuedLocation: 'Nơi cấp CMND ',
        cccd: 'CCCD ',
        cccdIssuedDate: 'Ngày cấp CCCD ',
        cccdIssuedLocation: 'Nơi cấp CCCD ',
        phone: 'Điện thoại ',
        startDate: 'Thời gian bắt đầu công việc (tính theo thời gian trên HĐ)',
        onboardingDatetime: 'Thời gian onboard',
        endDate: 'Thời gian dự kiến kết thúc thử việc',
        allowance: 'Trợ cấp kèm theo',
        internType: 'Loại Hợp đồng',
        salaryType: 'Phương thức trả lương',
        education: 'Trình độ văn hoá',
        internManager: 'Email quản lý trực tiếp (báo cáo công việc trực tiếp)',
        taxCode: 'Mã số thuế cá nhân',
        hasTaxCode: 'Nhân viên đã có mã số thuế (MST) chưa?',
        createCompanyEmail: 'Cần tạo mới Email Công ty?',
        reportingManager: 'Quản lý trực tiếp',
        existingEmail: 'Email hiện tại đang có',
        emailNote: 'Cách điều chỉnh Email',
        onboardingAgenda: 'Onboarding Agenda',
        contactPoint: 'Contact piont',
        benefit: 'Các hoạt động cần hoàn thành'
      },
      description: {
        taxCode: `<ul>
        <li>Chi tiết cách tra cứu xem <a href="https://drive.google.com/file/d/1WGU_n9p0jtl6f7TnrNTmduhiz-MAGB_g/view" target="_blank">tại đây</a></li>
        <li class="hl">Mã số thuế cá nhân là Bắt buộc</li></ul>
        `,
        taxCodeWarning: ``,
      }
    },
    42: {
      formName: 'Web event project request/Đăng ký dự án Microsite với team CODE',
      order: ['admins', 'fullname', 'email', 'game', 'includesIngameItems', 'projectName', 'projectGit', 'projectType', 'shortMechanismDescription', 'mockupLink', 'dataLink',
        'designDeadlineDate', 'liveDate', 'endDate', 'serviceEndDate', 'designerEmail', 'backendDeveloper', 'frontendDeveloper',
        'riskLevel', 'projectComplexity', 'projectLink', 'other'],
      highlight: ['riskLevel'],
      mapping: {
        fullname: 'PO name',
        email: 'PO email',
        game: 'Team',
        includesIngameItems: 'Sự kiện có gửi quà hay không?',
        projectName: 'Project name',
        projectGit: 'Git name',
        mockupLink: 'Link file mockup & logic',
        dataLink: 'Data to be provided after the end of the project',
        liveDate: 'Expected live date',
        endDate: 'Expected end date',
        serviceEndDate: 'Expected close service and admin panel',
        designDeadlineDate: 'Design PSD deadline',
        designerEmail: 'Designer\'s email',
        backendDeveloper: 'Backend developer',
        frontendDeveloper: 'Frontend developer',
        riskLevel: 'Risk level',
        other: 'Other information',
        shortMechanismDescription: 'Short mechanism description',
        projectType: 'Project type',
        projectComplexity: 'Project complexity',
        projectLink: 'Project link',
        admins: 'Danh sách Admin quản trị của site',
        adminEmail: 'Email của admin'
      },
      description: {

      }
    },
    43: {
      formName: 'ĐĂNG KÝ TUYỂN DỤNG CỘNG TÁC VIÊN',
      order: ['fullname',
        'email',
        'requestDate',
        'position',
        'directManagerEmail',
        'game',
        'subTeam',
        'requestCount',
        'headcountType',
        'removeEmail',
        'location',
        'workType',
        'onSite',
        'urgent',
        'jd',
        'hireReason',
        'hireReason1',
        'hireReason2',
        'hireReason3',
        'hireReason4',
        'hireReason5',
        'purpose',
        'startDate',
        'duration',
        'currentHeadCount',
        'currentFulltimeHeadCount',],
      highlight: ['riskLevel'],
      mapping: {
        fullname: 'Họ và tên người đăng ký',
        email: 'Email người đăng ký',
        requestDate: 'Ngày yêu cầu',
        position: 'Tên vị trí',
        directManagerEmail: 'Email quản lý trực tiếp của vị trí này',
        game: 'Bộ phận',
        subTeam: 'Phòng/Ban',
        requestCount: 'Số lượng cần tuyển',
        headcountType: 'Loại headcount',
        removeEmail: 'Email nhân sự cần thay thế',
        location: 'Địa điểm làm việc',
        workType: 'Loại hình làm việc',
        onSite: 'CTV có làm việc tại văn phòng không?',
        urgent: 'Mức độ cấp thiết',
        jd: 'Nội dung công việc & Yêu cầu tuyển dụng',
        hireReason: 'Lý do tuyển dụng',
        hireReason1: '(1) Tóm tắt nhu cầu của team đối với vị trí này dựa trên chiến lược dài hạn,  workload, priority, v.v..?',
        hireReason2: '(2) Các nguồn lực hiện tại không phục vụ được vì sao?',
        hireReason3: '(3) Vì sao cần CTV full time mà không phải một nguồn lực bán thời gian, tạm thời, làm remote?',
        hireReason4: '(4) Vì sao lại cần CTV full time mà không phải là một vị trí full time chính thức?',
        hireReason5: '(5) Comments khác',
        purpose: 'Mục tiêu sẽ giao cho vị trí này',
        startDate: 'Ngày dự kiến bắt đầu công việc',
        duration: 'Thời gian cần vị trí này',
        currentHeadCount: 'Số lượng HC Cộng tác viên hiện có trong team',
        currentFulltimeHeadCount: 'Số lượng HC Nhân viên chính thức hiện có trong team'
      },
      description: {
        position: `(VD: CTV Content Marketing, CTV Campaign Marketing, CTV Esports Marketing, CTV Product Operations...)`,
        subTeam: '(VD: Product/Marketing/Esports/Creative/Khác)',
        jd: `Theo mẫu JD tại <a href="https://docs.google.com/spreadsheets/d/1P1hq2m47IMDWZNskRJYVI9lvX1TEHIEZ/edit#gid=908517275" target="_blank"> đây </a>`,
        purpose: 'Liệt kê những mục tiêu (Goals & Objectives) dưới dạng định lượng, định tính mà vị trí này sẽ cần đạt được. Mục tiêu này nên là mục tiêu định kỳ hàng tháng, hàng quý.',
        duration: '(VD: 3 tháng, 6 tháng, 1 năm, dài hạn - không xác định)',
        currentHeadCount: '(bao gồm các vị trí đã phê duyệt, đang mở tuyển)',
        requestCount: 'Vui lòng ghi rõ số lượng tuyển thay thế/tuyển mới',
        currentFulltimeHeadCount: '(bao gồm các vị trí đã phê duyệt, đang mở tuyển)'
      }
    },
    44: {
      formName: 'ĐĂNG KÝ TUYỂN DỤNG NHÂN VIÊN',
      order: [
        'fullname',
        'email',
        'requestDate',
        'position',
        'requestCount',
        'expectedRanking',
        'game',
        'subTeam',
        'directManagerEmail',
        'location',
        'startDate',
        'headcountType',
        'removeEmail',
        'urgent',
        'jd',
        'hireReason',
        'hireReason1',
        'hireReason2',
        'hireReason3',
        'hireReason4',
        'hireReason5',
        'hireReason6',
        'purpose',
        'mission',
        'currentHeadCount',
        'currentFulltimeHeadCount',],
      highlight: ['riskLevel'],
      mapping: {
        fullname: 'Họ và tên người đăng ký',
        email: 'Email người đăng ký',
        requestDate: 'Ngày yêu cầu',
        position: 'Tên vị trí',
        directManagerEmail: 'Email quản lý trực tiếp của vị trí này',
        game: 'Bộ phận',
        subTeam: 'Phòng/Ban',
        expectedRanking: 'Cấp bậc mong đợi',
        requestCount: 'Số lượng cần tuyển',
        headcountType: 'Loại headcount',
        removeEmail: 'Email nhân sự cần thay thế',
        location: 'Địa điểm làm việc',
        workType: 'Loại hình làm việc',
        onSite: 'CTV có làm việc tại văn phòng không?',
        urgent: 'Mức độ cấp thiết',
        jd: 'Nội dung công việc & Yêu cầu tuyển dụng',
        hireReason: 'Lý do tuyển dụng',
        hireReason1: '(1) Tóm tắt nhu cầu của team đối với vị trí này dựa trên chiến lược dài hạn, workload, priority, v.v..?',
        hireReason2: '(2) Tại sao sao vị trí này quan trọng với hoạt động và mục tiêu của team?',
        hireReason3: '(3) Các nguồn lực hiện tại không phục vụ được vì sao?',
        hireReason4: '(4) Vì sao lại cần nhân sự chính thức mà không phải là một vị trí Cộng tác viên fulltime?',
        hireReason5: '(5) Vì sao cần nhân sự chính thức mà không phải một nguồn lực bán thời gian, tạm thời, làm remote?',
        hireReason6: '(6) Comments khác',
        purpose: 'Mục tiêu sẽ giao cho vị trí này',
        startDate: 'Ngày dự kiến bắt đầu công việc',
        mission: 'Liệt kê các nghiệp vụ/công việc cụ thể mà vị trí này cần đảm nhiệm',
        duration: 'Thời gian cần vị trí này',
        currentHeadCount: 'Số lượng HC Cộng tác viên hiện có trong team',
        currentFulltimeHeadCount: 'Số lượng HC Nhân viên chính thức hiện có trong team'
      },
      description: {
        position: `(VD: Content Marketing, Campaign Marketing, Esports Marketing, Product Operations...)`,
        subTeam: '(VD: Product/Marketing/Esports/Creative/Khác)',
        jd: `Theo mẫu JD tại <a href="https://docs.google.com/spreadsheets/d/19oiJPVdCkYsArBz6oxdQ9qJmtnPMghvg/edit#gid=1513326644" target="_blank"> đây </a>`,
        purpose: 'Liệt kê những mục tiêu (Goals & Objectives) dưới dạng định lượng, định tính mà vị trí này sẽ cần đạt được. Mục tiêu này nên là mục tiêu định kỳ hàng tháng, hàng quý.',
        duration: '(VD: 3 tháng, 6 tháng, 1 năm, dài hạn - không xác định)',
        currentHeadCount: '(bao gồm các vị trí đã phê duyệt, đang mở tuyển)',
        requestCount: 'Vui lòng ghi rõ số lượng tuyển thay thế/tuyển mới',
        mission: 'Ví dụ: Quản trị kênh Tiktok, làm content, chạy 2 campaigns lớn 1 tháng',
        currentFulltimeHeadCount: '(bao gồm các vị trí đã phê duyệt, đang mở tuyển)',
        expectedRanking: `Ví dụ: Analyst/Associate/Senior Associate/Assisstant Manager/Manager/Khác<br/>
        Tham khảo thêm tại <a href="https://rank.garena.com/Introduction" target="_blank">https://rank.garena.com/Introduction</a>`
      }
    },
    45: {
      formName: 'BOOKING FLIGHTS TICKET AND HOTELS',
      order: ['fullname', 'email', 'phone', 'managerEmail', 'game', 'purpose', 'from', 'to', 'colleaguesList',
        'projectCode', 'startDate1', 'startDate2', 'startDate3', 'roundTrip', 'endDate1', 'endDate2', 'endDate3',
        'otherInfomation', 'guestReason', 'guestInformation'],
      approveCenterColumns: ['purpose', 'from', 'to', 'startDate1', 'endDate1', 'projectCode', 'otherInfomation', 'includesWeekend', 'visitHome'],
      highlight: ['includesWeekend', 'weekendPurpose', 'weekendList'],
      mapping: {
        fullname: 'Fullname',
        email: 'Email',
        phone: 'Mobile',
        rank: 'Cấp bậc',
        managerEmail: 'Direct Manager',
        includesMe: 'Who do you book fights and hotels for?',
        purpose: 'Purpose of booking',
        game: 'My Department',
        from: 'Departure',
        to: 'Arrival',
        colleagues: 'Staffs travel with you',
        colleaguesList: 'Staffs travel with you',
        colleagueName: 'Fullname of staff travel with you',
        colleagueEmail: 'Email of staff travel with you',
        startDates: 'Departure time',
        startDate: 'Departure time',
        endDates: 'Return time',
        endDate: 'Return time',
        startDate1: 'Departure Time',
        roundTrip: 'Round trip?',
        endDate1: 'Return time',

        room1: 'Single room',
        room2: 'Double room',

        otherInfomation: 'Other Information',
        projectCode: 'Project Code',

        memberName: 'Fullname of staff travel with you',
        memberEmail: 'Email of staff travel with you',

      },
      description: {
        purpose: 'If you book flights/hotels for travel overseas, you have to write all below in English',
        otherInfomation: 'Please list out special demand, request'
      }
    },
    46: {
      formName: 'Đăng ký Hỗ trợ chi phí Đào tạo',
      order: ['divider1', 'fullname', 'email', 'phone', 'managerEmail', 'team',
        'courseName', 'courseWeb', 'courseSMART', 'courseEffect', 'courseFee',
        'courseStartDate', 'courseEndDate', 'courseDuration', 'courseFreq', 'courseAuthor', 'courseCertificate',
        'docTitle', 'docWeb', 'docSMART', 'docEffect', 'docFee',
        'courseEffectByManager', 'divider2', 'courseFeeByRec', 'courseTypeByRec', 'coursePaymentByRec',
      ],
      highlight: ['fullname', 'team', 'courseName', 'docTitle',
        'courseEffectByManager', 'courseFeeByRec', 'courseTypeByRec', 'coursePaymentByRec'],
      mapping: {
        divider1: 'Phần 1: Tổng quan nhu cầu của nhân sự',
        divider2: 'Phần 2: Đề xuất từ bộ phận Đào tạo',
        fullname: 'Fullname',
        email: 'Email',
        phone: 'Mobile',
        rank: 'Cấp bậc',
        team: 'Bộ phận của bạn',
        managerEmail: 'Email quản lý trực tiếp của bạn',
        subsidizeLevel: 'Bạn mong muốn đăng ký nhận mức hỗ trợ chi phí đào tạo nào dưới đây?',
        subsidizeType: 'Bạn muốn đăng ký hình thức hỗ trợ chi phí cho loại hình đào tạo bên ngoài nào?',
        courseName: 'Tên khoá học',
        courseWeb: 'Website của khóa học',
        courseSMART: 'Mục tiêu học tập mà bạn muốn đạt được',
        courseEffect: 'Ý nghĩa của khóa học đối với công việc của bạn / hoạt động kinh doanh của Công ty',
        courseFee: 'Tổng chi phí của khóa học (kèm đơn vị tiền tệ)',
        courseStartDate: 'Ngày khai giảng khoá học',
        courseEndDate: 'Ngày kết thúc khóa học',
        courseDuration: 'Thời lượng của khóa học',
        courseFreq: 'Tần suất của khóa học (nếu có)',
        courseAuthor: 'Tên của tổ chức đào tạo',
        courseCertificate: 'Tổ chức đào tạo đó có thể cung cấp chứng chỉ/xác nhận hoàn thành khóa học sau khi kết thúc khóa học không?',
        docTitle: 'Tiêu đề của tài liệu',
        docWeb: 'Trang web của tài liệu',
        docSMART: 'Mục tiêu học tập theo mô hình SMART (Specific - Measurable - Achievable - Relevant - Time-bound)',
        docEffect: 'Tác động/ý nghĩa của khóa học đối với kết quả hoạt động kinh doanh của công ty',
        docFee: 'Tổng chi phí của tài liệu (kèm đơn vị tiền tệ)',
        agreement: 'Tôi đồng ý tham gia vào các buổi chia sẻ nội bộ/đào tạo về những kiến thức đã được học hoặc hỗ trợ bộ phận Đào tạo phát triển các khóa học E-learning theo yêu cầu',

        courseEffectByManager: 'Kỳ vọng đầu ra và tác động của khóa học/tài liệu (từ quản lý)',
        courseFeeByRec: 'Chi phí (mức hỗ trợ) đề xuất',
        courseTypeByRec: 'Hình thức hỗ trợ đề xuất',
        coursePaymentByRec: 'Hình thức thanh toán đề xuất',

        feedback_before: 'Đánh giá trước khóa học (cho quản lý)',
        feedback_after: 'Đánh giá sau khóa học (cho quản lý)',
        feedback_self: 'Đánh giá sau khóa học (cho nhân viên)',
      },
      description: {
        purpose: 'If you book flights/hotels for travel overseas, you have to write all below in English',
        courseSMART: `Gợi ý (nhưng không bắt buộc): Có thể tham khảo mô hình SMART dưới đây để dễ dàng thiết lập mục tiêu hơn:<br/>
        - S: Bổ sung kiến thức nâng cao phân tích dữ liệu bằng công cụ Power BI để phục vụ công việc tracking data <br/>
        - M: Đạt mục tiêu xử lý dữ liệu sạch, có ý nghĩa để đưa ra quyết định next step phù hợp với team <br/>
        - A: Đưa ra phân tích & định hướng tốt hơn cho Team từ dữ liệu <br/>
        - R: Xử lý dữ liệu để đưa ra quyết định phù hợp với định hướng của sản phẩm và công ty<br/>
        - T: Hoàn thành khóa học trong 2 tháng, ứng dụng nâng cao được vào công việc<br/>
        `,
        courseEffect: `Gợi ý:<br/>
        Từ data có thể phân tích sâu hơn để tìm ra những giải pháp tối ưu trong việc tăng trưởng doanh thu + user<br/>
        Giao tiếp tiếng Trung tốt hơn giúp giảm thời gian giao tiếp, trao đổi trong các dự án, tăng hiệu suất làm việc với khu vực, đối tác nước ngoài`
      },
      feedback: {
        LEVELS: [
          { value: 'L0', label: 'Không: Thiếu năng lực trong một lĩnh vực hoặc chủ đề cụ thể' },
          { value: 'L1', label: 'Cơ bản: Hiểu kiến ​​thức cơ bản + Bước đầu ứng dụng thực tế' },
          { value: 'L2', label: 'Trung cấp: Hiểu khái niệm vững chắc + Ứng dụng thực tế' },
          { value: 'L3', label: 'Nâng cao: Có kiến thức chuyên môn đáng kể + Kinh nghiệm thực tế trong công việc, đạt tiêu chuẩn cao - và nhất quán' },
          { value: 'L4', label: 'Chuyên gia: Kiến thức sâu rộng, kỹ năng tinh tế + Kinh nghiệm lâu năm trong việc thực hiện năng lực ở tiêu chuẩn cao nhất' },
        ],
        AGREES: [
          { value: 1, label: 'Rất không hài lòng' },
          { value: 2, label: 'Không hài lòng' },
          { value: 3, label: 'Bình thường' },
          { value: 4, label: 'Hài lòng' },
          { value: 5, label: 'Rất hài lòng' },
        ],
        feedback_before: 'Đánh giá trước khóa học (cho quản lý)',
        feedback_after: 'Đánh giá sau khóa học (cho quản lý)',
        feedback_self: 'Đánh giá sau khóa học (cho nhân viên)',

        before: 'Đánh giá trước khóa học (cho quản lý)',
        after: 'Đánh giá sau khóa học (cho quản lý)',
        self: 'Đánh giá sau khóa học (cho nhân viên)',

        qualification: 'Năng lực cần thiết mà Nhân viên cần phải đạt được là gì?',
        evaluation: 'Bạn đánh giá thế nào về năng lực hiện tại của Nhân viên?',
        evaluationAfter: 'Khóa học / tài liệu học tập bên ngoài có mang lại bất kỳ thay đổi nào trong hành vi của Nhân viên của bạn không, ví dụ: anh ấy / cô ấy có áp dụng các kỹ năng / ứng dụng kiến ​​thức từ khóa học tại nơi làm việc của anh ấy / cô ấy không?',
        self1: 'Trải nghiệm chung với toàn bộ dịch vụ đào tạo được cung cấp',
        self2: 'Mức độ hài lòng với giải pháp / nội dung đào tạo',
        self3: 'Mức độ hài lòng với giảng viên / đối tác / tổ chức cung cấp nội dung',
        self4: 'Mức độ hài lòng với công tác tổ chức và hỗ trợ của Bộ phận Đào tạo?',
        self5: 'Vui lòng chia sẻ một điều mà bạn học được và có thể áp dụng trong thực tế công việc sau dịch vụ đào tạo này?',
        self6: 'Vui lòng chia sẻ thêm các phản hồi / gợi ý khác của bạn để nâng cao dịch vụ đào tạo?',
      }
    },
    47: {
      formName: 'Đặt cơm vận hành sự kiện/giải đấu',
      order: ['fullname', 'email', 'phone', 'managerEmail', 'game', 'league', 'eventType',
        'startDate', 'lateReason1', 'endDate', 'lateReason2', 'eventReason', 'meals', 'ggDrive', 'pic', 'note'],
      highlight: [],
      tableFields: ['meals'],
      tableFieldsHidden: ['Email', 'gigiTicketId'],
      mapping: {
        fullname: 'Fullname',
        email: 'Email',
        phone: 'Mobile',
        rank: 'Cấp bậc',
        game: 'Bộ phận của bạn',
        managerEmail: 'Email quản lý trực tiếp của bạn',
        league: 'Tên sự kiện',
        purpose: 'Mục đích phục vụ hoạt động kinh doanh',
        startDate: 'Ngày bắt đầu',
        endDate: 'Ngày kết thúc',
        lateReason1: 'Vui lòng giải thích lý do tạo ticket muộn',
        lateReason2: 'Vui lòng giải thích lý do tạo ticket muộn',
        meals: 'Bảng thông tin số phần cơm tổng',
        ggDrive: 'Link file đặt cơm (gg sheet)',
        note: 'Thông tin khác',
        eventType: 'Loại hoạt động',
        budget: 'Mức ngân sách',
        eventReason: 'Lý do cần cung cấp/hỗ trợ ăn uống trong sự kiện',
        pic: 'Yêu cầu đặt cơm qua',
      },
      description: {
        meals: 'Vui lòng tham khảo mẫu <a href="https://docs.google.com/spreadsheets/d/1AFRpgfd2CBlYRb-iGfFTDfV-YZYmjaWkGMYiVta4hKw/edit?gid=616737137#gid=616737137" target="_blank">tại đây</a> ',
        ggDrive: 'để admin double check'
      }
    },
    48: {
      formName: 'Duyệt tổng hợp cơm giải đấu',
      order: ['fullname', 'email', 'phone', 'managerEmail', 'game', 'league',
        'startDate', 'lateReason1', 'endDate', 'lateReason2', 'meals', 'ggDrive', 'note'],
      highlight: [],
      tableFields: ['meals'],
      tableFieldsHidden: ['Email', 'gigiTicketId'],
      mapping: {
        fullname: 'Fullname',
        email: 'Email',
        phone: 'Mobile',
        rank: 'Cấp bậc',
        game: 'Bộ phận của bạn',
        managerEmail: 'Email quản lý trực tiếp của bạn',
        league: 'Tên giải đấu',
        startDate: 'Ngày bắt đầu',
        endDate: 'Ngày kết thúc',
        lateReason1: 'Vui lòng giải thích lý do tạo ticket muộn',
        lateReason2: 'Vui lòng giải thích lý do tạo ticket muộn',
        meals: 'Bảng thông tin số phần cơm tổng',
        ggDrive: 'Link file đặt cơm (gg sheet)',
        note: 'Thông tin khác',
      },
      description: {
        meals: 'Vui lòng tham khảo mẫu <a href="https://docs.google.com/spreadsheets/d/1BnLuE6imySUwedFgIyiqH4ZC2O5QYx4p/edit#gid=1248793833" target="_blank">tại đây</a> ',
        ggDrive: 'để admin double check'
      }
    }
  },
}